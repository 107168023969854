import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Hero } from "../components/Hero";
import GeneralLayout from "../layout/GeneralLayout";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { BG_IMAGE_1_URL } from "../components/APIs";
import emailjs from "emailjs-com";
const SatisfactionSurvey = () => {
  const date = Date();
  const form = useRef();
  // Formik configuration
  const formik = useFormik({
    initialValues: {
      surveyDate: date,
      satisfaction: "",
      serviceUsage: "",
      supportSatisfaction: "",
      improvementSuggestion: "",
      recommendation: "",
      comments: "",
      servicesUser: "",
      relationshipToServiceUser: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      satisfaction: Yup.string().required("Please select satisfaction level"),
      serviceUsage: Yup.string().required(
        "Please select service usage duration"
      ),
      supportSatisfaction: Yup.string().required(
        "Please select support satisfaction level"
      ),
      servicesUser: Yup.string().required(
        "Please select if you are the service user"
      ),
      // relationshipToServiceUser:Yup.string().required(
      //   "Please enter your relationship with service provider"
      // ),
      recommendation: Yup.string().required(
        "Please select recommendation level"
      ),
    }),
    onSubmit: (values) => {
      console.log(values);
      // Handle form submission here
      const template_params = {
        surveyDate: values.surveyDate,
        servicesUser: values.servicesUser,
        satisfaction: values.satisfaction,
        serviceUsage: values.serviceUsage,
        supportSatisfaction: values.supportSatisfaction,
        improvementSuggestion: values.improvementSuggestion,
        recommendation: values.recommendation,
        comments: values.comments,
        relationshipToServiceUser: values.relationshipToServiceUser,
        name: values.name,
      };
      emailjs
        .sendForm(
          "service_jwgygaj",
          "template_ao8gkxt",
          form.current,
          "r87_e3ImlcQckqG-N"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            alert("Survey Submitted. Thank you for your time!");
            formik.resetForm(); 
          },
          (err) => {
            console.log("FAILED...", err);
            alert("FAILED...", err);
          }
        );
    },
  });

  return (
    <GeneralLayout
      hasHero={true}
      pageContent={
        <>
          <div className="w-full">
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="Service User Satisfaction Survey"
              breadcrumb={
                <>
                  <Link href="/">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />

            <div className="max-w-7xl mx-auto p-8">
              <form
                ref={form}
                onSubmit={formik.handleSubmit}
                className="space-y-4"
              >
                <div>
                  <label className="block mb-1">Name:</label>

                  <input
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className={`border p-2 rounded w-full ${
                      formik.errors.name && formik.touched.name
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                  {formik.errors.name &&
                    formik.touched.name && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.name}
                      </div>
                    )}
                </div>
                {/* Date of survey */}
                <div>
                  <label className="block mb-1">
                    Are you the service User:
                  </label>

                  <select
                    name="servicesUser"
                    onChange={(e) => {
                      formik.handleChange(e);
                      // Optionally clear the relationship field when user selects "Yes"
                      if (e.target.value === "yes") {
                        formik.setFieldValue("relationshipToServiceUser", "");
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.servicesUser}
                    className={`border p-2 rounded w-full ${
                      formik.errors.servicesUser && formik.touched.servicesUser
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                {/* Conditionally render the "Relationship to Service User" field if "No" is selected */}
                {formik.values.servicesUser === "no" && (
                  <div>
                    <label className="block mb-1">
                      Relationship to Service User:
                    </label>

                    <input
                      name="relationshipToServiceUser"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.relationshipToServiceUser}
                      className={`border p-2 rounded w-full ${
                        formik.errors.relationshipToServiceUser &&
                        formik.touched.relationshipToServiceUser
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                )}

                {/* Overall satisfaction */}
                <div>
                  <label className="block mb-1">
                    Overall, how satisfied are you with the service?
                  </label>
                  <select
                    name="satisfaction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.satisfaction}
                    className={`border p-2 rounded w-full ${
                      formik.errors.satisfaction && formik.touched.satisfaction
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="Extremely satisfied">
                      Extremely satisfied
                    </option>
                    <option value="Very Satisfied">Very Satisfied</option>
                    <option value="Satisfied">Satisfied</option>
                    <option value="Not Satisfied">Not Satisfied</option>
                    <option value="Extremely Not Satisfied">
                      Extremely Not Satisfied
                    </option>
                  </select>
                  {formik.errors.satisfaction &&
                    formik.touched.satisfaction && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.satisfaction}
                      </div>
                    )}
                </div>

                {/* Service usage duration */}
                <div>
                  <label className="block mb-1">
                    How long have you been using this service?
                  </label>
                  <select
                    name="serviceUsage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.serviceUsage}
                    className={`border p-2 rounded w-full ${
                      formik.errors.serviceUsage && formik.touched.serviceUsage
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="0-6 months">0 - 6 months</option>
                    <option value="6 months - 1 year">6 months - 1 year</option>
                    <option value="1 year - 3 years">1 year - 3 years</option>
                  </select>
                  {formik.errors.serviceUsage &&
                    formik.touched.serviceUsage && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.serviceUsage}
                      </div>
                    )}
                </div>

                {/* Support satisfaction */}
                <div>
                  <label className="block mb-1">
                    How do you feel about the support given to you or your loved
                    one?
                  </label>
                  <select
                    name="supportSatisfaction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.supportSatisfaction}
                    className={`border p-2 rounded w-full ${
                      formik.errors.supportSatisfaction &&
                      formik.touched.supportSatisfaction
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="Extremely satisfied">
                      Extremely satisfied
                    </option>
                    <option value="Very Satisfied">Very Satisfied</option>
                    <option value="Satisfied">Satisfied</option>
                    <option value="Not Satisfied">Not Satisfied</option>
                    <option value="Extremely Not Satisfied">
                      Extremely Not Satisfied
                    </option>
                  </select>
                  {formik.errors.supportSatisfaction &&
                    formik.touched.supportSatisfaction && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.supportSatisfaction}
                      </div>
                    )}
                </div>

                {/* Suggestions for improvement */}
                <div>
                  <label className="block mb-1">
                    How do you feel we could improve?
                  </label>
                  <textarea
                    name="improvementSuggestion"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.improvementSuggestion}
                    className="border p-2 rounded w-full border-gray-300"
                  />
                </div>

                {/* Recommendation */}
                <div>
                  <label className="block mb-1">
                    How likely is it that you would recommend this service to a
                    friend?
                  </label>
                  <select
                    name="recommendation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.recommendation}
                    className={`border p-2 rounded w-full ${
                      formik.errors.recommendation &&
                      formik.touched.recommendation
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  >
                    <option value="">Select</option>
                    <option value="Not Likely">Not Likely</option>
                    <option value="Likely">Likely</option>
                    <option value="Extremely Likely">Extremely Likely</option>
                  </select>
                  {formik.errors.recommendation &&
                    formik.touched.recommendation && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.recommendation}
                      </div>
                    )}
                </div>

                {/* Additional comments */}
                <div>
                  <label className="block mb-1">
                    Do you have any other comments, questions, or concerns?
                  </label>
                  <textarea
                    name="comments"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.comments}
                    className="border p-2 rounded w-full border-gray-300"
                  />
                </div>

                {/* Submit button */}
                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded mt-4"
                >
                  Submit Survey
                </button>
              </form>
            </div>
          </div>
        </>
      }
    />
  );
};

export default SatisfactionSurvey;

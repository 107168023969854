import { Referee } from "../onboarding/Referee";
import { Dbs } from "../onboarding/Dbs";

export const USER_LEVELS = {
  SIGNUP: 1,
  APPLICATION_PROCESS: 2,
  INTERVIEW: 3,
  PRE_EMPLOYMENT_CHECK: 4,
  CONTRACT_AGREEMENT: 5,
  STAFF: 6,
  ADMIN: 7,
  FAIL: 9,
};

export const services = [
  {
    title: "Web Development",
    description: "Building amazing web applications",
    image: "web-development-image.jpg",
  },
  {
    title: "Graphic Design",
    description: "Creating stunning visual designs",
    image: "graphic-design-image.jpg",
  },
  // Add more service objects as needed
];
export const oonboading = [
  {
    id: 1,
    type: "text",
    title: "Referee",
    link: "#",
    component: <Referee />,
  },
  {
    id: 2,
    type: "doc",
    title: "DBS",
    link: "#",
    component: <Dbs />,
  },
];
export const dbst_type = [
  { value: "Basic DBS", label: "Basic DBS" },
  { value: "Enhanced DBS", label: "Enhanced DBS" },
  {
    value: " Enhanced with Barred List(s) DBS.",
    label: "Enhanced with Barred List(s) DBS",
  },
];

export const DataBoolean = [
  { value: 1, label: "No" },
  { value: 0, label: "Yes" },
];
export const DriverLicenseType = [
  { value: "Valid Full UK", label: "Valid Full UK" },
  { value: "Valid International", label: "Valid International" },
];
export const employement_statement_array = [
  {
    value:
      "This is my first job since last 6 April and I have not been receiving taxable Jobseeker Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension.",
    label:
      "This is my first job since last 6 April and I have not been receiving taxable Jobseeker Allowance, Employment and Support Allowance, taxable Incapacity Benefit, State or Occupational Pension.",
  },
  {
    value:
      "This is now my only job but since last 6 April I have had another job, or received taxable Jobseekers Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.",
    label:
      "This is now my only job but since last 6 April I have had another job, or received taxable Jobseekers Allowance, Employment and Support Allowance or taxable Incapacity Benefit. I do not receive a State or Occupational Pension.",
  },
  {
    value:
      "As well as my new job, I have another job or receive a State or Occupational Pension.",
    label:
      "As well as my new job, I have another job or receive a State or Occupational Pension.",
  },
];
export const nokRelationship = [
  { label: "Brother", value: "Brother" },
  { label: "Sister", value: "Sister" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Spouse", value: "Spouse" },
  { label: "Child", value: "Child" },
  { label: "Friend", value: "Friend" },
  // Add more relationships as needed
];

export const interviewFeedbackChoices = [
  { value: 5, label: "Very Clear" },
  { value: 4, label: "Clear" },
  { value: 3, label: "Neutral" },
  { value: 2, label: "Unclear" },
  { value: 1, label: "Very Unclear" },
];
export const likelihoodChoices = [
  { value: 5, label: "Very Likely" },
  { value: 4, label: "Likely" },
  { value: 3, label: "Neutral" },
  { value: 2, label: "Unlikely" },
  { value: 1, label: "Very Unlikely" },
];

export const interviewAligned = [
  { value: 5, label: "Aligned Perfectly" },
  { value: 4, label: "Mostly Aligned" },
  { value: 3, label: "Neutral" },
  { value: 2, label: "Partially Aligned" },
  { value: 1, label: "Misaligned" },
];

export const yesNoSomewhat = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Somewhat", label: "Somewhat" },
];
export const yesNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const loanPlan = [
  { value: "Plan 1", label: "Plan 1" },
  { value: "Plan 2", label: "Plan 2" },
];
export const rating = [
  { value: 5, label: 5 },
  { value: 4, label: 4 },
  { value: 3, label: 3 },
  { value: 2, label: 2 },
  { value: 1, label: 1 },
];

export const infoSource = [
  { value: "Social Media", label: "Social Media" },
  { value: "From a Freind", label: "From a Freind" },
  { value: "Printed flyers", label: "Printed flyers" },
];

export const refPos = [
  { value: "CEO/Founder", label: "CEO/Founder" },
  { value: "Director", label: "Director" },
  { value: "Manager", label: "Manager" },
  { value: "Line Manager", label: "Line Manager" },
  { value: "Supervisor", label: "Supervisor" },
  { value: "Head of Department", label: "Head of Department" },
  { value: "other", label: "Others" },
];
export const refCandidatePos = [
  { value: "Care Support Worker", label: "Care Support Worker" },
  { value: "Registered Nurse", label: "Registered Nurse" },
  { value: "others", label: "Others" },
];
export const assessment = [
  { value: 5, label: "Excellent" },
  { value: 4, label: "Good" },
  { value: 3, label: "Average" },
  { value: 2, label: "Poor" },
];
export const clientele = [
  { id: 1, client: "NURSING HOMES", color: "bg-purple-900" },
  { id: 2, client: "RESIDENTIAL CARE FACILITIES", color: "bg-blue-700" },
  { id: 3, client: "SUPPORTED LIVING FACILITIES", color: "bg-orange-700" },
  { id: 4, client: "HOSPITALS", color: "bg-blue-500" },
  { id: 5, client: "REHABILITATION CENTRES", color: "bg-purple-900" },
  { id: 6, client: "MENTAL HEALTH FACILITIES", color: "bg-blue-900" },
  { id: 7, client: "PRIVATE HOME CARE", color: "bg-orange-900" },
  { id: 8, client: "NHS TRUSTS", color: "bg-blue-500" },
  { id: 9, client: "LOCAL AUTHORITIES", color: "bg-green-900" },
  { id: 10, client: "INTEGRATED CARE BOARDS", color: "bg-blue-700" },
];

export const profession = [
  { id: 1, client: "Doctors", color: "bg-[#1e40af]" },
  { id: 2, client: "Registered General Nurses", color: "bg-[#1e1b4b]" },
  { id: 3, client: "Mental Health Nurses", color: "bg-[#9333ea]" },
  { id: 4, client: "Paediatric Nurses", color: "bg-[#2563eb]" },
  { id: 5, client: "Community Nurses", color: "bg-[#0d9488]" },
  { id: 6, client: "Midwives", color: "bg-[#4d7c0f]" },
  { id: 7, client: "Health Care Assistants", color: "bg-[#eab308]" },
  { id: 8, client: "Senior Health Care Assistants", color: "bg-[#e11d48]" },
  { id: 9, client: "Support Workers", color: "bg-[#064e3b]" },
  { id: 10, client: "Advanced Support Workers", color: "bg-[#1d4ed8]" },
  { id: 11, client: "Kitchen Assistants", color: "bg-[#701a75]" },
  { id: 12, client: "Housekeepers", color: "bg-[#27272a]" },
  { id: 13, client: "Team Leaders", color: "bg-[#7c3aed]" },
  { id: 14, client: "Chefs", color: "bg-[#22c55e]" },
  {
    id: 15,
    client: "Allied Health Professionals",
    color: "bg-[#2e1065]",
  },
  { id: 16, client: "Including Physiotherapists", color: "bg-[#2e1065]" },
  { id: 16, client: "Occupational Therapists", color: "bg-[#2e1065]" },
  { id: 16, client: "Social Workers", color: "bg-[#2e1065]" },
  { id: 16, client: "and Dietitians", color: "bg-[#2e1065]" },
  { id: 16, client: "Radiologists", color: "bg-[#a21caf]" },
  { id: 17, client: "Medical Device Specialists", color: "bg-[#7e22ce]" },
];
export const whyus = [
  { id: 1, client: "We are TIMELY", color: "bg-[#22c55e]" },

  { id: 3, client: "We are available 24/7", color: "bg-[#2e1065]" },
  { id: 4, client: "We are Highly Responsive", color: "bg-[#e11d48]" },
  {
    id: 5,
    client: "Our staff are experienced, well trained & motivated",
    color: "bg-[#701a75]",
  },

  {
    id: 6,
    client:
      "Our staff are well paid which makes them to be happy to work for us",
    color: "bg-[#1e40af]",
  },
  {
    id: 2,
    client: "We are passionate about our services",
    color: "bg-[#7c3aed]",
  },
  {
    id: 7,
    client:
      "We have a steady and reliable pool of staff to meet your needs per time",
    color: "bg-[#064e3b]",
  },
];
export const corevalues = [
  {
    color: "bg-[#3b82f6]",
    id: 1,
    title: "TRUST",
    icon: "/images/trust.png",
    desc: "We pride ourselves in nurturing positive relationships at all strata of our organization with our commitment to culture of honesty, psychological safety and mutual respect for employees and clients.",
  },
  {
    color: "bg-[#0284c7]",
    id: 2,
    title: "INTEGRITY",
    icon: "/images/integrity.png",
    desc: "We conduct our business with honesty, transparency, and integrity, maintaining the trust and confidence of all stakeholders.",
  },

  {
    color: "bg-[#f97316]",
    id: 4,
    title: "MUTUALITY",
    icon: "/images/mutual.png",
    desc: "We foster collaborative partnerships with both clients and staff, working together to achieve mutual success and satisfaction",
  },
  {
    color: "bg-[#0d9488]",
    id: 5,
    title: "EMPATHY",
    icon: "/images/trust.png",
    desc: "We don’t train our staff to be empathetic, we simply employ empathetic people and support them to deliver person-centred services to our clients",
  },
  {
    color: "bg-[#064e3b]",

    id: 6,
    title: "LOYALTY",
    icon: "/images/loyalty.png",
    desc: "Our clients rely on us to provide dependable staffing solutions, because we are loyal to their service objectives and we take pride in our track record of loyalty, reliability and consistency in meeting their needs.",
  },
  {
    color: "bg-[#b91c1c]",
    id: 3,
    title: "YOURS",
    icon: "/images/yours.png",
    desc: "We Are Yours because we are committed to delivering the highest quality of service to our clients and candidates, ensuring that every placement meets the highest standards of excellence and professionalism.",
  },
];
export const carecorevalues = [
  {
    color: "bg-[#3b82f6]",
    id: 1,
    title: "TRUST",
    icon: "/images/trust.png",
    desc: "We pride ourselves in nurturing positive relationships at all strata of our organization with our commitment to culture of honesty, psychological safety and mutual respect for employees and clients",
  },
  {
    color: "bg-[#0284c7]",
    id: 2,
    title: "INTEGRITY",
    icon: "/images/integrity.png",
    desc: "We conduct our business with honesty, transparency, and integrity, maintaining the trust and confidence of all stakeholders.",
  },

  {
    color: "bg-[#f97316]",
    id: 4,
    title: "MUTUALITY",
    icon: "/images/mutual.png",
    desc: "We foster collaborative partnerships with both clients and staff, working together to achieve mutual success and satisfaction",
  },
  {
    color: "bg-[#0d9488]",
    id: 5,
    title: "EMPATHY",
    icon: "/images/trust.png",
    desc: "We don’t train our staff to be empathetic, we simply employ empathetic people and support them to deliver person-centred services to our clients.",
  },
  {
    color: "bg-[#064e3b]",

    id: 6,
    title: "LOYALTY",
    icon: "/images/loyalty.png",
    desc: "Our clients rely on us to provide dependable staffing solutions, because we are loyal to their service objectives and we take pride in our track record of loyalty, reliability and consistency in meeting their needs.",
  },
  {
    color: "bg-[#b91c1c]",
    id: 3,
    title: "YOURS",
    icon: "/images/yours.png",
    desc: " We Are Yours because we are committed to delivering the highest quality of service to our clients and candidates, ensuring that every placement meets the highest standards of excellence and professionalism.",
  },
];
export const gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "non-binary", label: "Non-binary" },
  { value: "genderqueer", label: "Genderqueer" },
  { value: "transgender", label: "Transgender" },
  { value: "genderfluid", label: "Genderfluid" },
  { value: "agender", label: "Agender" },
  { value: "bigender", label: "Bigender" },
];

"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ListItem,
  List,
  Link,
  ListIcon,
  Slide,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { Logo } from "../../components/Logo";
import { AvatarMenu } from "./AvatarMenu";
import { useAuthentication } from "../../auth/useAuthentication";

export default function CareNavbar({ navTextColor, hasHero = false }) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const isAuthenticated = useAuthentication();
  return (
    <Box>
      <Flex
        // bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"90px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={0}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Logo
          desktopNav={<DesktopNav navTextColor={navTextColor} />}
          height={"50px"}
        />

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {isAuthenticated ? (
            <AvatarMenu />
          ) : (
            <>
              <Button
                color={navTextColor}
                as={"a"}
                fontSize={"sm"}
                fontWeight={700}
                variant={"link"}
                href={"/login"}
              >
                Login
              </Button>
              <Button
                as={"a"}
                display={{ base: "none", md: "inline-flex" }}
                fontSize={"sm"}
                fontWeight={700}
                color={"white"}
                bg={"blue.400"}
                href={"/register"}
                _hover={{
                  bg: "blue.50",
                  color: "blue.400",
                }}
              >
                Register
              </Button>
            </>
          )}
        </Stack>
      </Flex>
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <Drawer
          onClose={onClose}
          isOpen={isOpen}
          in={isOpen}
          animateOpacity
          size={"lg"}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton py={5} px={5} size={"2em"} color={"white"} />
            <DrawerHeader
              py={5}
              bg={"blue.400"}
              borderBottom={"1px"}
              borderBottomColor={"gray.50"}
              color={"white"}
            >
              {" "}
              Site Menu
            </DrawerHeader>
            <DrawerBody bg={"blue.400"}>
              <MobileNav />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Slide>
      {/* <Collapse >
       
      </Collapse> */}
    </Box>
  );
}

const DesktopNav = ({ navTextColor }) => {
  const linkColor = "dark:text-white light:text-gray-200";
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <>
      {NAV_ITEMS.map((navItem) => (
        <div key={navItem.label}>
          <a
            className={
              `p-2 text-lg font-semibold hover:text-slate-500  ` + navTextColor
            }
            href={navItem.href ?? "#"}
          >
            {navItem.label}
          </a>
        </div>
      ))}
    </>
  );
};

const MobileNav = () => {
  return (
    <Stack
      // bg={useColorModeValue("white", "gray.800")}
      color={"red"}
      display={{ md: "none" }}
      w="100%"
    >
      <List spacing={3} fontSize={{ base: "14pt", md: "18pt", sm: "14pt" }}>
        {NAV_ITEMS.map((menuItem, index) => (
          <ListItem key={index} borderBottomWidth={1}>
            <a href={menuItem.href} width={"full"}>
              <Text>{menuItem.label}</Text>
            </a>
            {menuItem.children && (
              <List ml={4} mt={2}>
                {menuItem.children.map((childItem, childIndex) => (
                  <ListItem key={childIndex}>
                    <Link href={childItem.href}>
                      <Text>{childItem.label}</Text>
                    </Link>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Home",
    href: "/recruitment",
  },
  { label: "Clientele", href: "/recruitment/clientele" },
  { label: "Pricing", href: "/recruitment/pricing" },
  {
    label: "Vacancy Position",
    href: "/recruitment/career",
  },
];

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function CustomRedirect() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectTo = determineRedirectPath(location.pathname);
    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [location, navigate]);

  const determineRedirectPath = (path) => {
    if (path === "/carecoordinator") {
      return "/career/251107427505671";
    }
    if (path === "/commcareworker") {
      return "/career/231088329301360";
    }
    // Add more conditions here if needed
    return null;
  };

  return null; // or a loading spinner, message, etc.
}

export default CustomRedirect;

import { useEffect, useState } from "react";

import {
  useColorMode,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { FiLogOut, FiUser, FiUserCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom/dist";
import { useApiData } from "../../components/GetData";
import Cookies from "js-cookie";

import { API_BASE_URL } from "../../components/APIs";

export const AvatarMenu = () => {
  const { toggleColorMode } = useColorMode(); // For toggling light/dark mode
  const [user, setUserInfo] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  // const userDetails = useUserContext();
  const {
    data: userDetails,
    loading,
    fetchData,
  } = useApiData("application/getUserDetails");
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setUserInfo(userDetails);
    }
  }, [userDetails]);

  const handleLogout = async () => {
    sessionStorage.clear();
    localStorage.clear();
    Cookies.remove("userData");
    Cookies.remove("uid");
    Cookies.remove("isLoggedin");
    Cookies.remove("userLevel");
    Cookies.remove("token");
    navigate("/login");
  };
  return (
    <>
      {!userDetails ? (
        <Spinner color="red" />
      ) : (
        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              userDetails?.passport == 1 ? (
                <img
                  src={API_BASE_URL + "uploads/" + userDetails?.avater}
                  alt={userDetails?.fname}
                  className=" w-[50px] h-[50px] my-auto object-cover   rounded-full"
                />
              ) : (
                <Avatar size="sm" name={user?.fname + " " + user?.lname} />
              )
            }
            variant="link"
          />

          <MenuList>
            <MenuItem
              icon={<FiUser />}
              // onClick={() => toggleColorMode()}
            >
              {user?.fname + " " + user?.lname}
            </MenuItem>
            <MenuItem
              icon={<FiUserCheck />}
              as={"a"}
              href="/application/personal-info"
            >
              My Application
            </MenuItem>
            {user?.level > 5 && (
              <MenuItem icon={<FiUser />} as={"a"} href="#">
                My Dashboard
              </MenuItem>
            )}
            <MenuItem icon={<FiLogOut />} onClick={handleLogout}>
              Logout {user?.fname}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );
};

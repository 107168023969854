import {
  Box,
  Container,
  Divider,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";

import Footer from "./component/Footer";
import GeneralNavbar from "./component/GeneralNavbar";
import { useEffect } from "react";
import { useState } from "react";
import CareNavbar from "./component/CareNavbar";
import RecruitmentNavbar from "./component/RecruitmentNavbar";
import PortaNavBar from "./component/PortaNavBar";
interface pageProps {
  page_type?: "care" | "recruitment" | "portal" | undefined;
  pageContent: ReactNode;
}
export default function GeneralLayout(props: pageProps) {
  const { pageContent, page_type } = props;
  const [navBackground, setNavBackground] = useState("transparent"); // State for background color
  const [navTextColor, setNavTextColor] = useState("text-white"); // State for background color
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 1) {
        setNavBackground("bg-white"); // Change to the desired background color
        setNavTextColor("text-gray-700"); // Change to the desired background color
      } else {
        setNavBackground("transparent");
        setNavTextColor("text-white");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        // transition="background-color .7s ease-in-out"
        zIndex={2}
        className={
          `w-full fixed  transition-opacity ease-in-out delay-150 duration-300 ` +
          navBackground
        }
      >
        <Box width={"85%"} m={"auto"}>
          {page_type == undefined && (
            <GeneralNavbar navTextColor={navTextColor} />
          )}
          {page_type == "care" && <CareNavbar navTextColor={navTextColor} />}
          {page_type == "recruitment" && (
            <RecruitmentNavbar navTextColor={navTextColor} />
          )}
          {page_type == "portal" && (
            <PortaNavBar navTextColor={navTextColor} />
          )}
        </Box>
      </Box>
      <Divider />
      <Box
        width={"full"}
        m={"auto"}
        color={useColorModeValue("gray.800", "gray.50")}
      >
        <Box>{pageContent}</Box>
      </Box>

      <Footer />
    </>
  );
}

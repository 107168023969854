import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import GeneralLayout from "../../layout/GeneralLayout";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { CustomButton } from "../../components/Buttons";

import { ArrowBackIcon } from "@chakra-ui/icons";
const Pricing = () => {
  const hover = {
    backgroundColor: "blue.400",
    color: "white",
    transform: "translateX(5px)",
  };
  const buttonTransition =
    '"background-color 0.3s, color 0.3s, transform 0.3s"';
  return (
    <GeneralLayout
      page_type="care"
      pageContent={
        <>
          <Box w={"full"} gap={10}>
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="Our Pricing"
              breadcrumb={
                <>
                  <Link href="/">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />

            <Box py={"7rem"}>
              <Box
                display="flex"
                w={{ base: "93%", md: "80%" }}
                m={"auto"}
                gap={2}
                flexDirection={{ base: "column", md: "row" }}
              >
                {/* Navigation Tab Link */}

                <Flex
                  flex={1}
                  padding="20px"
                  // Change to your desired background color
                  // boxShadow="md"
                  width={{ base: "100%", md: "500px" }} // Adjust width as needed
                  //   py={7}
                  px={"1em"}
                  rounded={20}
                  // color="white" // Change to your desired text color
                  fontWeight="bold"
                  gap={10}
                  alignContent={"center"}
                  justifyContent={"center"}
                  flexDir={"column"}
                  className="text-center"
                >
                  <h2 className="text-4xl text-balance md:text-7xl">
                    We are happy to discuss with you further about our prices.
                  </h2>

                  <Box>
                    <CustomButton
                      label="Contact Us"
                      as={"a"}
                      link="/contact"
                      bg={"yellow.300"}
                    />
                  </Box>
                </Flex>
              </Box>
            </Box>

            {/* <Flex
              padding="20px"
              // Change to your desired background color
              // boxShadow="md"
              width={{ base: "100%", md: "100%" }} // Adjust width as needed
              py={"7em"}
              px={"1em"}
              bg={"blue.400"}
              mx={"auto"}
              textAlign={"center"}
              // color="white" // Change to your desired text color
              fontWeight="bold"
              alignContent={"center"}
              justifyContent={"center"}
              flexDir={"column"}
              // backgroundImage={"url('/images/contact-us.jpg')"}
              blur={"2"}
              backgroundPosition={"center"}
              bgRepeat={"no-repeat"}
              backgroundSize={"cover"}
              backdropBlur={500}
              _before={{
                opacity: 0.5,
                zIndex: -1,
              }}
              gap={5}
            >
              <Heading
                fontSize={{ base: "3xl", md: "5xl" }}
                lineHeight={"30px"}
                textAlign={"center"}
                // fontWeight={400}
              >
                Our Team Is Always Ready!
              </Heading>
              <Text
                fontSize={{ base: "16px", md: "24px" }}
                fontWeight={"normal"}
                width={["100%", "50%"]}
                mx={"auto"}
              >
                Our team is always ready to assess your needs, conduct a
                comprehensive risk assessment, develop an inclusive
                person-centred care plan, and deliver a safe, high-quality and
                consented care and support plan unique to the individual.
              </Text>
              <Box>
                <CustomButton
                  label="Make Enquiry now"
                  as={"a"}
                  link="/contact"
                  bg={"yellow.400"}
                />
              </Box>
              <Box>
                <CustomButton
                          label="Contact Us"
                          as={"a"}
                          link="/contact"
                          bg={"yellow.400"}
                        /> 
              </Box>
            </Flex> */}
          </Box>
        </>
      }
    />
  );
};

export default Pricing;

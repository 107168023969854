import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
interface InputProps {
  label?: string; // Optional label text for the input field
  type?:
    | "text"
    | "email"
    | "password"
    | "number"
    | "tel"
    | "url"
    | "search"
    | "date"
    | "month"; // Optional input type (defaults to 'text')
  name: string; // Required prop for the input name attribute
  placeholder?: string; // Optional placeholder text
  value?: string; // Required prop for the input value
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Required prop for handling value changes
  errorMessage?: string; // Optional prop for displaying an error message
  isInvalid?: any; // Optional prop to indicate an invalid state (affects styling)
  className?: string;
  formLabel: string;
  icon: string;
  disabled: string;
  formErroMsg: string;
  ref: string;
  fontSize: string;
  w: string;
  onBlur: string;
  popover?: true | false;
  popLabel: string;
  isRequired?: true | false; // Optional prop for adding custom classes
}
export function CustomInput(props: InputProps) {
  const {
    formLabel,
    type = "text",
    icon,
    placeholder,
    name,
    isInvalid,
    value,
    onChange,
    disabled,
    formErroMsg,
    ref,
    fontSize = "10pt",
    w,
    onBlur,
    popover,
    popLabel,
    isRequired = false,
    ...rest
  } = props;
  return (
    <FormControl isInvalid={isInvalid} w={w} isRequired={isRequired}>
      <FormLabel display={"flex"} fontSize={fontSize}>
        {/* <Flex gap={3}> */}
        {formLabel} &nbsp;
        {popover && (
          <Popover>
            <PopoverTrigger>
              <Box
                tabIndex="0"
                role="button"
                aria-label="Some box"
                children={
                  popLabel ? (
                    <Text color={"blue"} textDecoration={"underline"}>
                      {popLabel}
                    </Text>
                  ) : (
                    <InfoIcon
                      bg={"black"}
                      rounded={"full"}
                      fontSize={"1.5em"}
                      color={"yellow"}
                    />
                  )
                }
              />
            </PopoverTrigger>
            <PopoverContent bg="white" color="#000">
              <PopoverCloseButton bg="blue" />
              <PopoverBody w={"95%"}>{popover}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {/* </Flex> */}
      </FormLabel>
      <Input
        onBlur={onBlur}
        className="custom-input"
        type={type}
        placeholder={placeholder}
        name={name}
        py={4}
        // borderWidth={2}
        borderColor={"gray.200"}
        fontSize={"10pt"}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
      <FormErrorMessage>{formErroMsg}</FormErrorMessage>
    </FormControl>
  );
}

export function CustumSelect({
  formLabel,
  options,
  name,
  isInvalid,
  value,
  onChange,
  disabled,
  placeholder,
  label,
  w,
  formErroMsg,
  ...rest
}) {
  return (
    <FormControl isInvalid={isInvalid} w={w}>
      <FormLabel>{formLabel}</FormLabel>
      <Select
        isInvalid={isInvalid}
        placeholder={placeholder}
        // mr={-4}
        height={"40px"}
        fontSize={15}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        label={label}
        {...rest}
        
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{formErroMsg}</FormErrorMessage>
    </FormControl>
  );
}
export function CustumSelectPosition({
  formLabel,
  options,
  name,
  isInvalid,
  value,
  onChange,
  disabled,
  placeholder,
  label,
  w,
}) {
  return (
    <FormControl isInvalid={isInvalid} w={w}>
      <FormLabel fontSize={"12pt"}>{formLabel}</FormLabel>
      <Select
        isInvalid={isInvalid}
        placeholder={placeholder}
        // mr={-4}
        height={"40px"}
        fontSize={15}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        label={label}
      >
        {options.map((option) => (
          <option key={option.value} value={option.vacancy_id}>
            {option.job_title}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
export function CustomTextarea({
  formLabel,
  placeholder,
  name,
  isInvalid,
  value,
  onChange,
  disabled,
  formErroMsg,
  ref,
  fontSize = "12pt",
  w,
}) {
  return (
    <FormControl isInvalid={isInvalid} w={w}>
      <FormLabel fontSize={fontSize}>{formLabel}</FormLabel>
      <Textarea
        placeholder={placeholder}
        name={name}
        // py={6}
        fontSize={15}
        value={value}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
      />
      <FormErrorMessage>{formErroMsg}</FormErrorMessage>
    </FormControl>
  );
}

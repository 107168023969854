import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";

import GeneralLayout from "../../layout/GeneralLayout";
import { Hero } from "../../components/Hero";
import { BG_IMAGE_1_URL } from "../../components/APIs";
import { CustomButton } from "../../components/Buttons";

import { ArrowBackIcon } from "@chakra-ui/icons";
import { carecorevalues, corevalues } from "../../util/data";
const AboutCare = () => {
  const hover = {
    backgroundColor: "blue.400",
    color: "white",
    transform: "translateX(5px)",
  };
  const buttonTransition =
    '"background-color 0.3s, color 0.3s, transform 0.3s"';
  return (
    <GeneralLayout
      hasHero={true}
      page_type="care"
      pageContent={
        <>
          <Box w={"full"} gap={10}>
            <Hero
              imageURL={BG_IMAGE_1_URL}
              heroTitle="About Litmus Care"
              breadcrumb={
                <>
                  <Link href="/care">
                    <ArrowBackIcon fontSize={20} /> Back{" "}
                  </Link>
                </>
              }
            />
            <div className="relative isolate overflow-hidden bg-slate-50 py-24 sm:py-32">
              {/* <img
                alt=""
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center opacity-50"
              /> */}
              <div
                aria-hidden="true"
                className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-blue-400 to-blue-900 opacity-20"
                />
              </div>
              <div
                aria-hidden="true"
                className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
              >
                <div
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-blue-400 to-blue-900 opacity-20"
                />
              </div>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-auto">
                  <h2 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-6xl text-center">
                    Who We are
                  </h2>
                  <p className="mt-6 text-lg leading-8 text-slate-700">
                    {/* Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat aliqua. */}
                  </p>
                </div>
                <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-slate-900 sm:grid-cols-2 md:flex md:flex-wrap lg:gap-x-10 ">
                    <p>
                      Litmus Care is a CQC registered domiciliary care service
                      provider of person-centred, high-quality, compassionate
                      and safe care services to support service users with
                      day-to-day tasks that through incapacity or illness can no
                      longer be performed safely or independently, in a way that
                      promotes their dignity, privacy and independence.
                    </p>
                    <p>
                      {" "}
                      We believe everyone should be able to maximise their
                      quality of life and do so from the comfortable
                      surroundings of their own home, should they wish. Our
                      business will seek compassionate and caring staff to
                      ensure each of our care users is provided with the care
                      and support they deserve as we aim to:
                      <ul className="text-left list-item list-disc indent-3 ml-5">
                        <li>
                          Deliver high-quality person-centred care to all our
                          service users,
                        </li>
                        <li>Remain professional but always caring</li>
                        <li>Be forward thinking and innovative</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gradient-to-tl from-blue-700 to-blue-950 ">
              <div
                m={"auto"}
                className="flex flex-col-reverse justify-center text-center py-24 md:py-32 mx-auto max-w-7xl px-5 gap-5 "
              >
                {/* Navigation Tab Link */}
                <div className="space-y-8 flex flex-col justify-center content-center flex-1">
                  <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                    Our Vission
                  </h2>
                  <p className="text-center text-balance text-[12pt] font-normal text-slate-200">
                    To be the foremost and most reliable home care service
                    provider in the country
                  </p>
                </div>
                <div className="space-y-8 flex flex-col justify-center content-center flex-1">
                  <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                    Our Mission
                  </h2>
                  <p className="text-center text-balance text-[12pt] font-normal text-slate-200">
                    Our mission is to deliver compassionate, safe and
                    high-quality care that respects the individuality of our
                    service users while helping them to achieve their goals
                    through our responsive, timely, supportive and bespoke care
                    and support services that promotes their dignity and
                    independence.
                  </p>
                </div>{" "}
              </div>
            </div>

            <div className="w-[100%] m-auto justify-center items-center py-20 space-y-10 bg-[#f8fafc]">
              <h2 className="text-4xl text-balance md:text-7xl font-bold text-center">
                OUR CORE VALUES
              </h2>
              <p className="text-[24px] text-center text-balance">
                Our core value has been summarized as TIMELY and defined below:
              </p>
              <div className="flex flex-col md:flex-row w-[80%] m-auto justify-center items-center">
                <div className="flex-1">
                  <div className="grid md:grid-cols-3  gap-4 justify-center items-center text-balance ">
                    {carecorevalues &&
                      corevalues.map((value, index) => (
                        <div
                          className={
                            `flex flex-col text-center h-[auto] p-5 rounded-xl transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  ` +
                            value.color
                          }
                        >
                          <div className="flex flex-col items-center py-5 px-5 ">
                            <div className="flex w-[100px] h-[100px] justify-center items-center">
                              {" "}
                              <img
                                alt=""
                                className="m-auto w-[80px] "
                                src={value.icon}
                              />
                            </div>
                            <p className="font-bold text-4xl text-white">
                              {value.title}
                            </p>
                          </div>
                          <p className="text-balance text-base text-white ">
                            {value.desc}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row py-24 md:py-32 mx-auto max-w-6xl px-5 gap-5">
              {/* Navigation Tab Link */}

              <div className="space-y-8 flex flex-col justify-center content-center flex-1">
                <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
                  24/7 SUPPORT
                </h2>
                <p className="text-left text-balance text-[12pt] font-normal">
                  We have a dedicated team that is available round-the-clock to
                  provide support and assistance to both clients and staff,
                  ensuring prompt resolution of any staffing challenges or
                  concerns promptly.
                </p>

                <span className="inline-flex  ">
                  <a
                    href="/contact"
                    className="text-base   shadow-lg  bg-blue-500  text-black flex-none px-5 rounded-lg py-2  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                  >
                    Contact us now
                  </a>
                </span>
              </div>
              <div className="flex-1">
                <Image
                  className="w-full max-w-none rounded-xl"
                  src="/images/recruitment.jpg"
                />
              </div>
            </div>
          </Box>
        </>
      }
    />
  );
};

export default AboutCare;

"use client";

import { Stack, Box, useToast, Spinner, Image } from "@chakra-ui/react";

import { OnboardingLayout } from "../layout/OnboardingLayout";
import { CustumSelect, CustumSelectPosition } from "../components/Input";
import { CustomButton } from "../components/Buttons";
import FileInput from "../components/FIleInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CustomSearchableSelect,
  ListCountries,
} from "../components/SelectCountry";

import { useEffect, useState } from "react";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";
import { LoadingSpinner } from "../components/LoadingSpinner";

import DynamicPageTitle from "../components/PageTitle ";

import { useNavigate } from "react-router-dom";
import useUserContext from "../components/UserContext";
import Cookies from "js-cookie";

export default function Uniform() {
  const navigate = useNavigate();

  const { userDetails, loading, getUsersInfo } = useUserContext();

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    getUsersInfo();
  }, []);
  const toast = useToast();

  const handleFileChange = (files) => {
    console.log("Selected files:", files);
    // You can perform actions with the selected files here
  };
  const uid = Cookies.get("uid");
  const formik = useFormik({
    initialValues: {
      uniform_size: "",
      user_id: "",
    },
    validationSchema: Yup.object({
      uniform_size: Yup.string().required("Please select your uniform size"),
    }),
    onSubmit: (data) => {
      console.log(data);
      setLoading(true);
      const url = API_BASE_URL + "final/saveUniform";
      axios
        .post(url, data)
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Profile Update",
              description: response.data.message,
              position: "top",
              status: "success",
              isClosable: true,
            });
            setLoading(false);
            navigate("/onboarding/bank");
          } else {
            toast({
              title: "Profile Update Error",
              description: response.data.message,
              position: "top",
              status: "error",
              isClosable: true,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast({
            title: "error",
            description: error.message,
            position: "top",
            status: "error",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (userDetails) {
      formik.setValues({
        uniform_size: userDetails?.uniform_size ,
        user_id: uid || "",
      });
    }
    setLoading(false);
  }, [userDetails]);
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <DynamicPageTitle pageTitle={"Personal Info"} />
      {userDetails && (
        <OnboardingLayout
          pageTitle="Uniform Size"
          mainContent={
            <Stack
              gap={4}
              direction={{ base: "column", md: "column", sm: "column" }}
              as={"form"}
              onSubmit={formik.handleSubmit}
            >
              <input
                value={formik.values.user_id}
                onChange={formik.handleChange}
                type="hidden"
                name="user_id"
              />
              {/* Names */}

              <form className="flex flex-col">
                <Image
                  src={"/litmus-uniform.png"}
                  alt=""
                  height={283}
                  width={200}
                />
                <p className="text-lg  font-bold">
                  Unisex Scrub Tunic Specification:{" "}
                </p>
                <ul className="list-disc list-inside mx-5">
                  <li> Fit: Regular Fit</li>
                  <li>V Neck Line</li>
                  <li>Patch Pocket on left chest (as worn)</li>
                  <li> Two Patch Pockets on Lower Front</li>
                  <li> Washable up to 60 degrees</li>
                  <li>Available UK Sizes: XS to 4XL</li>
                  <li>Fabric: 65% Polyester & 35% Cotton</li>
                  <li> Fabric Weight: 150gsm</li>
                  <li>Brand: Uneek Clothing</li>
                  <li>Style: Scrub Tunic</li>
                </ul>

                <div className="md:w-1/4">
                  <CustumSelect
                    name="uniform_size"
                    isInvalid={
                      formik.errors.uniform_size && formik.touched.uniform_size
                    }
                    value={formik.values.uniform_size}
                    onChange={formik.handleChange}
                    placeholder={"Select your size"}
                    options={[
                      { label: "Small", value: "S" },
                      { label: "Medium", value: "M" },
                      { label: "Large", value: "L" },
                      { label: "Extra Large", value: "X" },
                    ]}
                    formLabel="Select your size"
                    formErroMsg={formik.errors.uniform_size}
                  />
                </div>
                <div className="absolute bottom-0">
                  <CustomButton label="Save Bank Details" />
                </div>
              </form>
              <Box py={5} gap={5} display={"flex"} flexDir={"row"}>
                {isLoading ? (
                  <Spinner
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <CustomButton
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Save"
                  />
                )}
                <Box justifySelf={"center"}>
                  <CustomButton
                    as={"a"}
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Next"
                    link="/onboarding/bank"
                  />
                </Box>
              </Box>
            </Stack>
          }
        />
      )}
    </>
  );
}

"use client";

import { Stack, Box, useToast, Spinner, Image } from "@chakra-ui/react";

import { OnboardingLayout } from "../layout/OnboardingLayout";
import { CustomInput } from "../components/Input";
import { CustomButton } from "../components/Buttons";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useEffect, useState } from "react";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";
import { LoadingSpinner } from "../components/LoadingSpinner";

import DynamicPageTitle from "../components/PageTitle ";

import { useNavigate } from "react-router-dom";
import useUserContext from "../components/UserContext";
import Cookies from "js-cookie";

export default function BankDetails() {
  const navigate = useNavigate();

  const { userDetails, loading } = useUserContext();

  const [isLoading, setLoading] = useState(true);

  const toast = useToast();

  const uid = Cookies.get("uid");
  const formik = useFormik({
    initialValues: {
      reference: "",
      building_society_reference: "",
      account_name: "",
      account_number: "",
      banker: "",
      sort_code: "",
      user_id: uid,
    },
    validationSchema: Yup.object({
      reference: Yup.string().required("Please Enter your Bank Reference"),
      building_society_reference: Yup.string().required(
        "Please Enter your Bank Reference"
      ),
      // account_name: Yup.string().required("Please Enter your Account name"),
      account_number: Yup.string().required("Please Enter your account number"),
      banker: Yup.string().required("Please Enter your Bank Name"),
      sort_code: Yup.string().required("Please Enter your Bank Sort code"),
    }),
    onSubmit: (data) => {
      setLoading(true);
      const bank_details = JSON.stringify(data);

      console.log(bank_details);
      const url = API_BASE_URL + "final/saveBankDetails";
      axios
        .post(url, { bank_details: bank_details, user_id: uid })
        .then((response) => {
          if (response.data.status === 200) {
            toast({
              title: "Profile Update",
              description: response.data.message,
              position: "top",
              status: "success",
              isClosable: true,
            });
            setLoading(false);
            navigate("/onboarding/nightwork");
          } else {
            toast({
              title: "Profile Update Error",
              description: response.data.message,
              position: "top",
              status: "error",
              isClosable: true,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast({
            title: "error",
            description: error.message,
            position: "top",
            status: "error",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    const bank = userDetails?.bank_details;
    const bank_details = bank ? JSON.parse(bank) : null;
    if (bank_details) {
      formik.setValues({
        // account_name: userDetails?.fname + " " + userDetails?.lname,
        banker: bank_details.banker,
        branch: bank_details.branch,
        reference: bank_details.reference,
        account_number: bank_details.account_number,
        sort_code: bank_details.sort_code,
        building_society_reference: bank_details.building_society_reference,
      });
    }
    setLoading(false);
  }, [userDetails]);
  if (loading) {
    return <LoadingSpinner />;
  }
  function isAgeValid(dateString) {
    let today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthdate has occurred already this year
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age >= 16;
  }

  return (
    <>
      <DynamicPageTitle pageTitle={"Personal Info"} />
      {userDetails && (
        <OnboardingLayout
          pageTitle="Bank Details"
          mainContent={
            <Stack
              gap={4}
              direction={{ base: "column", md: "column", sm: "column" }}
              as={"form"}
              onSubmit={formik.handleSubmit}
            >
              {/* <input
                value={formik.values.user_id}
                onChange={formik.handleChange}
                type="hidden"
                name="user_id"
              /> */}
              {/* Names */}

              <div className="grid md:grid-cols-2 gap-4">
                <CustomInput
                  formLabel="Bank Name"
                  type="text"
                  name="banker"
                  isInvalid={formik.errors.banker && formik.touched.banker}
                  value={formik.values.banker}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.banker}
                />

                <CustomInput
                  formLabel="Account Name"
                  type="text"
                  name="account_name"
                  isInvalid={
                    formik.errors.account_name && formik.touched.account_name
                  }
                  value={userDetails?.fname + " " + userDetails?.lname}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.account_name}
                 
                />
                <CustomInput
                  formLabel="Account Number"
                  type="text"
                  name="account_number"
                  isInvalid={
                    formik.errors.account_number &&
                    formik.touched.account_number
                  }
                  value={formik.values.account_number}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.account_number}
                />
                <CustomInput
                  formLabel="Sort Code"
                  type="text"
                  name="sort_code"
                  isInvalid={
                    formik.errors.sort_code && formik.touched.sort_code
                  }
                  value={formik.values.sort_code}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.sort_code}
                />
                <CustomInput
                  formLabel="Bank Reference"
                  type="text"
                  name="reference"
                  isInvalid={
                    formik.errors.reference && formik.touched.reference
                  }
                  value={formik.values.reference}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.reference}
                />
                <CustomInput
                  formLabel="Building Society Reference"
                  type="text"
                  name="building_society_reference"
                  isInvalid={
                    formik.errors.building_society_reference &&
                    formik.touched.building_society_reference
                  }
                  value={formik.values.building_society_reference}
                  onChange={formik.handleChange}
                  formErroMsg={formik.errors.building_society_reference}
                />
              </div>

              <Box py={5} gap={5} display={"flex"} flexDir={"row"}>
                {isLoading ? (
                  <Spinner
                    thickness="3px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                ) : (
                  <CustomButton
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Save"
                  />
                )}
                <Box justifySelf={"center"}>
                  <CustomButton
                    as={"a"}
                    type="submit"
                    bg={"blue.400"}
                    colorScheme="blue"
                    label="Next"
                    link="/onboarding/nightwork"
                  />
                </Box>
              </Box>
            </Stack>
          }
        />
      )}
    </>
  );
}

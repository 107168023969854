
export const API_BASE_URL = 'https://litmusservices.co.uk/litmusapi/';
// export const API_BASE_URL = 'https://litmusapi.litmusservices.co.uk/';

export const BG_IMAGE_1_URL = '/images/bg-hero.jpg';
// export const API_BASE_URL = 'http://localhost/litmus-api/';
export const COUNTRY_LIST_API =
  'https://litmusservices.co.uk/litmusapi/getCountries.php';
export const SECTOR_LIST_API =
  'https://litmusservices.co.uk/api/getCountries.php';
export const UPLOADE_CARE_PUBLIC_KEY = '57d88dd8d51c2c9c5f06';
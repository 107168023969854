import React, { useCallback, useEffect, useRef, useState } from "react";

import { OnboardingLayout } from "../layout/OnboardingLayout";

import axios from "axios";
import jsPDF from "jspdf";

import {
  Box,
  Button,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../components/APIs";
import AgencyAgreementDoc from "../agreement/AgreementDoc";
import PersonalDetails from "./PersonalDetails";
import WorkHistory from "./WorkHistory";

const ProfileSummary = () => {
  const [userDetails, setUserDetails] = useState();
  const { user_id } = useParams();

  const getUsersInfo = useCallback(async () => {
    try {
      setLoading(true);
      const url = API_BASE_URL + "application/getUserDetails";

      const response = await axios.post(url, { user_id: user_id });

      setUserDetails(response?.data);
      // setUser(response?.data);

      localStorage.setItem("userInfo", JSON.stringify(response?.data));
      Cookies.set("getUserInfo", true);
      setLoading(false);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  }, []); // Empty dependency array ensures the function reference doesn't change

  useEffect(() => {
    getUsersInfo();
  }, [getUsersInfo]);

  const currentDate =
    userDetails?.check_list == "1"
      ? new Date(userDetails?.date_of_appointment)
      : new Date();
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "long", // Full month name
    day: "numeric", // Day of the month
    year: "numeric", // Full year
  });

  const category = userDetails?.job_category;
  const sector = userDetails?.sector;

  const name = userDetails?.fname + " " + userDetails?.lname;
  const [isLoading, setLoading] = useState();
  const contractTemplateRef = React.useRef(null);
  const containerStyle = {
    width: "600px",
    margin: "0 auto",
    // paddingTop: '.2em',
    paddingBottom: "1em",
    paddingLeft: "1em",
    paddingRight: "1em",
    fontFamily: "Arial, sans-serif",
    "page-break-after": "always",
    boxShadow: "lg",
  };
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleGeneratePdf = () => {
    // setGenerating(true);
    
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
      floatPrecision: "smart", // or "smart", default is 16
    });
    // Adding the fonts.
    // doc.setFont('Verdana', 'normal');

    doc.html(contractTemplateRef.current, {
      async callback(doc) {
        doc.save(name + " Application Form");
        window.location.reload();
      },
      margin: [30, 0, 30, 0],
      autoPaging: "text",
    });
  };

  const agree = userDetails?.agree === 1 ? true : false;
  const signature = userDetails?.fname + " " + userDetails?.lname;

  const cand_name =
    userDetails?.fname?.trim() +
    " " +
    userDetails?.lname.trim() +
    " " +
    userDetails?.oname.trim();
  const [employeeadd, setEmployeeAdd] = useState("");
  useEffect(() => {
    // Move the employee address calculation inside the useEffect
    if (userDetails?.applicant_type === "International") {
      const address = JSON.parse(userDetails?.international_address);
      setEmployeeAdd(
        address.house_number +
          ", " +
          address.address_line1 +
          ", " +
          address.town_city +
          ", " +
          address.state +
          ", " +
          address.applicant_location
      );
    } else {
      setEmployeeAdd(
        userDetails?.address_line1 +
          " " +
          userDetails?.town_city +
          " " +
          userDetails?.county +
          ", " +
          userDetails?.post_code
      );
    }
  }, [userDetails]);
  // handleGeneratePdf();
  const offerCreatedOn = userDetails?.date_created;
  return (
    <>
      <OnboardingLayout
        noAuth={false}
        pageTitle="Contract Agreement"
        mainContent={
          <Box width={"100%"} pos={"relative"}>
            
              <div style={containerStyle} ref={contractTemplateRef} className="shadow-md">
                <div className="flex flex-row items-center font-normal text-[11px]  space-y-3  text-justify ">
                  <img alt="" src="/litmus-logo.png" height={10} />
                  <div className="flex flex-col">
                    <p className="text-4xl text-red-500 font-bold">
                      Litmus Care
                    </p>
                    <p>
                      Sherbourne House, Humber Avenue, Coventry, CV1 2AQ
                      jobs@litmusservices.co.uk | www.litmusservices.co.uk
                      02080797305
                    </p>
                  </div>
                </div>
                <PersonalDetails user_id={user_id} />
                {/* <WorkHistory user_id={544} /> */}
              </div>
       
            {/* {application_type == "" || (null && "<CareOffer />")} */}

            <Button
              pos={"fixed"}
              top={"50%"}
              bg={"white"}
              left={"45%"}
              isLoading={isLoading}
              onClick={handleGeneratePdf}
              // width={"300px"}
              loadingText="Generating Document"
              variant="outline"
              spinnerPlacement="start"
              colorScheme="blue"
            >
              Download 
              
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ProfileSummary;

"use client";

import {
  Stack,
  Box,
  Text,
  Spinner,
  useToast,
  Input,
  Alert,
  AlertIcon,
  Flex,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { OnboardingLayout } from "../layout/OnboardingLayout";
import { useCallback, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaCloudUploadAlt } from "react-icons/fa";
import useUserContext from "../components/UserContext";
import { API_BASE_URL } from "../components/APIs";

export default function UploadPassportPhoto() {
  const uid = Cookies.get("uid");
  const { userDetails, loading: dataLoading } = useUserContext();
  const [uploadedFileMessage, setUploadedFileMessage] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [loading, setLoading] = useState(false);
  const MAX_FILE_SIZE = 750 * 828; // 700KB

  const handleFileUpload = useCallback(
    (acceptedFiles) => {
      setUploadedFileMessage(null);
      setLoading(true);
      const file = acceptedFiles[0]; // Get the first accepted file (single file upload)

      if (!file) {
        setUploadError(
          "Invalid file format. Please upload an image, docx, doc, or pdf file. <br/> File must be lesser than 700KB"
        );
        setLoading(false);
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        setLoading(false);
        setUploadError("File size exceeds the maximum allowed (700KB).");
        return;
      }

      const formData = new FormData();
      formData.append("file", file); // Append the file to the form data
      formData.append("user_id", uid); // Append the user ID to the form data

      axios
        .post(API_BASE_URL + "uploads/UploadPassport", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setUploadedFileMessage(
            "File Uploaded! Please click save to complete your upload"
          );
          setLoading(false);
          setUploadError(null);
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          setUploadError("File upload failed. Please try again.");
        });
    },
    [uid, MAX_FILE_SIZE]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    accept: ".jpg, .jpeg, .png, .gif, .pdf", // Allow specific file types
    maxFiles: 1, // Limit to a single file
    maxSize: MAX_FILE_SIZE, // 700KB maximum file size
  });

  return (
    <OnboardingLayout
      pageTitle="Upload Passport Photograph"
      mainContent={
        <Stack gap={4} direction={{ base: "column", md: "column", sm: "row" }}>
          <Text className="text-xl">
            <br />
            Upload your passport photograph here
          </Text>
          <Text>
            Upload your passport photograph here <br />
            Recommended size: 750px * 828px {userDetails?.avater}
          </Text>
          <div className=" ">
            <img
              src={
                API_BASE_URL + "uploads/" + userDetails?.avater
              }
              alt={uid}
              className=" w-[350px] h-auto my-auto object-cover   rounded-xl"
            />
          </div>

          <Box>
            {loading ? (
              <Box bg="blue.100" py="5" px="5" w="100%">
                <Spinner color="blue.600" />
              </Box>
            ) : (
              <>
                {!uploadedFileMessage && (
                  <Flex
                    {...getRootProps()}
                    className="dropzone"
                    bg="blue.600"
                    py="5"
                    px="5"
                    w="350px"
                    color="white"
                    align="center"
                    justify="space-between"
                    pointerEvents="all"
                  >
                    <Input {...getInputProps()} />
                    <Text>
                      Drag and drop a file here, or click to select a file
                      (image or PDF)
                    </Text>
                    <FaCloudUploadAlt justifySelf="flex-end" size="2em" />
                  </Flex>
                )}
              </>
            )}

            <div className="w-[350px]">
              {uploadError && (
                <Alert status="error">
                  <AlertIcon />
                  {uploadError}
                </Alert>
              )}
              {uploadedFileMessage && (
                <Alert status="success" py="5" px="5">
                  <AlertIcon />
                  {uploadedFileMessage}
                </Alert>
              )}
            </div>
          </Box>
        </Stack>
      }
    />
  );
}

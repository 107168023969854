import React, { useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import ReactStars from "react-rating-stars-component";

import GeneralLayout from "../layout/GeneralLayout";
import { CustomButton } from "../components/Buttons";
import axios from "axios";
import { CustomInput, CustomTextarea } from "../components/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API_BASE_URL } from "../components/APIs";
const FeedbackForm = () => {
  const [save, setSave] = useState(false);
  const formik = useFormik({
    initialValues: {
      facilityName: "",
      contactPerson: "",
      // dateOfService: "",
      // endOfService: "",
      qualityOfStaff: "",
      comments: "",
      recommend: "",
    },
    validationSchema: Yup.object({
      facilityName: Yup.string().required("This field is required"),
      contactPerson: Yup.string().required("This field is required"),
      // dateOfService: Yup.string().required("This field is required"),
      recommend: Yup.string().required(
        "Please Let us know if you can recommend"
      ),
      // comments: Yup.string().required("Please rate us as required"),
      // responseTime: Yup.string().required("Please rate us as required"),
      qualityOfStaff: Yup.string().required("Please rate us as required"),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      try {
        const url = API_BASE_URL + "feedback/saveFeedbackForm";
        const response = await axios.post(url, values);
        console.log(response.data.status); // Handle success response
        if ((response.data.status = 200)) {
          setSave(true);
        }
      } catch (error) {
        console.error("Error saving feedback:", error); // Handle error
      }
    },
  });

  return (
    <GeneralLayout
      pageContent={
        <Box w={"full"} py={["0em", "6em"]} pt={["6em", ""]}>
          <Box
            p={5}
            // bg="gray.50"
            borderRadius="xl"
            boxShadow={["none", save ? "none" : "lg"]}
            width={["full", "80%"]}
            mx={"auto"}
          >
            {save ? (
              <>
                <Alert
                  gap={5}
                  rounded={"xl"}
                  top={"20"}
                  width={["full", "50%"]}
                  mx={"auto"}
                  status="success"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  py={10}
                  // height="200px"
                >
                  <AlertIcon boxSize="60px" />
                  <AlertTitle
                    mt={4}
                    mb={4}
                    fontSize={["28pt", "32pt"]}
                    lineHeight={["30pt", "42pt"]}
                  >
                    Your Feedback was submitted!
                  </AlertTitle>
                  <AlertDescription
                    maxWidth="lg"
                    fontSize={"16pt"}
                    lineHeight={"18pt"}
                  >
                    Thank you for taking the time to complete this feedback
                    form. Your input is highly valued and will help us continue
                    to provide exceptional service to our clients.
                  </AlertDescription>
                  <CustomButton
                    label={"Home Page"}
                    link={"/"}
                    as={"a"}
                    colorScheme={"blue"}
                    py={5}
                  />
                  <CustomButton
                    label={"Submit another feedback"}
                    link={"/feedback"}
                    as={"a"}
                    py={5}
                    variant={"link"}
                    colorScheme={"blue"}
                  />
                </Alert>
              </>
            ) : (
              <>
                <Heading fontSize={["3xl", "5xl"]} fontWeight="bold" mb={4}>
                  LITMUS SERVICES LIMITED
                </Heading>
                <Text fontSize={["2xl", "3xl"]} fontWeight="500" mb={4}>
                  FEEDBACK AND REVIEW FORM
                </Text>
                <Text fontSize={"16pt"} my={2}>
                  Thank you for choosing Litmus Services Limited and for
                  trusting us with your staffing needs. We value your feedback
                  as it helps us ensure we are providing the highest quality of
                  service to you, our clients. Please take a few moments to
                  share your experience with our agency by completing this
                  feedback form.
                </Text>

                <Stack
                  as={"form"}
                  spacing={5}
                  method="post"
                  onSubmit={formik.handleSubmit}
                >
                  <Stack gap={2} direction={["column", "column"]}>
                    <CustomInput
                      formLabel={"Name of Organisation (Branch and Location)"}
                      type="text"
                      placeholder="Name of Organisation (Branch and Location)"
                      name="facilityName"
                      fontSize="16pt"
                      isInvalid={
                        formik.errors.facilityName &&
                        formik.touched.facilityName
                      }
                      onChange={formik.handleChange}
                      value={formik.values.facilityName}
                      formErroMsg={formik.errors.facilityName}
                    />
                    {/* Client Information */}
                    <CustomInput
                      formLabel={"Name & Position of person filling this form"}
                      type="text"
                      placeholder="Name & Position of person filling this form"
                      name="contactPerson"
                      fontSize="16pt"
                      isInvalid={
                        formik.errors.contactPerson &&
                        formik.touched.contactPerson
                      }
                      onChange={formik.handleChange}
                      value={formik.values.contactPerson}
                      formErroMsg={formik.errors.contactPerson}
                    />
                  </Stack>
                  {/* <Stack gap={2} direction={["column", "row"]}>
                    <CustomInput
                      formLabel={"Start Date of Service"}
                      type="month"
                      name="dateOfService"
                      fontSize="16pt"
                      isInvalid={
                        formik.errors.dateOfService &&
                        formik.touched.dateOfService
                      }
                      onChange={formik.handleChange}
                      value={formik.values.dateOfService}
                      formErroMsg={formik.errors.dateOfService}
                    />
            
                    <CustomInput
                      formLabel={"End Date of Service"}
                      type="month"
                      name="endOfService"
                      fontSize="16pt"
                      isInvalid={
                        formik.errors.endOfService &&
                        formik.touched.endOfService
                      }
                      popover
                      popLabel="Leave Blank it still in service"
                      onChange={formik.handleChange}
                      value={formik.values.endOfService}
                      formErroMsg={formik.errors.endOfService}
                    />
                  </Stack> */}

                  {/* Rating Scale */}
                  <div>
                    <Text fontSize="3xl" fontWeight={500}>
                      Rating Scale:
                    </Text>
                    <Text fontSize={"16pt"}>
                      {" "}
                      Please provide a review of our agency with respect to
                      RELIABILITY, RESPONSE TIME AND QUALITY OF STAFF on a scale
                      from 1★ to 5★, with 1★ being the lowest and 5★ being the
                      highest.
                    </Text>
                  </div>

                  <FormControl
                    bg={formik.errors.qualityOfStaff && `red.50`}
                    isInvalid={
                      formik.touched.qualityOfStaff &&
                      formik.touched.qualityOfStaff
                    }
                  >
                    {/* <FormLabel
                        fontSize={"16pt"}
                        color={formik.errors.qualityOfStaff && `red`}
                      >
                        <b>Quality of Staff:</b> How competent are the agency
                        staff in carrying out their duties effectively?
                      </FormLabel> */}
                    <ReactStars
                      value={formik.values.qualityOfStaff}
                      count={5}
                      onChange={(value) =>
                        formik.setFieldValue("qualityOfStaff", value)
                      }
                      size={50}
                      activeColor="#ffd700"
                    />
                    <FormErrorMessage fontSize={"16pt"} colorScheme="red">
                      {formik.errors.qualityOfStaff}
                    </FormErrorMessage>
                  </FormControl>

                  {/* Comments */}
                  <div>
                    <Text fontSize="3xl" fontWeight={500}>
                      Comments/Additional Feedback:
                    </Text>
                    <Text fontSize={"16pt"}>
                      {" "}
                      Please provide any additional comments or feedback
                      regarding your experience with the agency and the staff.
                      Your input is valuable to us and helps us improve our
                      services.
                    </Text>
                  </div>
                  <FormControl mt={6}>
                    <CustomTextarea
                      type="text"
                      placeholder=" Comments/Additional Feedback"
                      name="comments"
                      fontSize={"16pt"}
                      isInvalid={
                        formik.errors.comments && formik.touched.comments
                      }
                      onChange={formik.handleChange}
                      value={formik.values.comments}
                      formErroMsg={formik.errors.comments}
                    />
                    <FormLabel fontSize={"16pt"}></FormLabel>
                  </FormControl>
                  {/* Overall Satisfaction */}

                  {/* <FormControl
                    isInvalid={
                      formik.errors.overallRating &&
                      formik.touched.overallRating
                    }
                  >
                    <FormLabel fontSize={"16pt"}>
                      Overall Satisfaction/Rating
                    </FormLabel>
                    <RadioGroup
                      value={formik.values.overallRating}
                      color={formik.errors.qualityOfStaff && `red`}
                    >
                      <Stack direction={["column", "row"]}>
                        {[
                          "Very Dissatisfied",
                          "Dissatisfied",
                          "Neutral",
                          "Satisfied",
                          "Very Satisfied",
                        ].map((rating) => (
                          <Radio
                            name="overallRating"
                            onChange={formik.handleChange}
                            key={rating}
                            value={rating}
                            fontSize={"16pt"}
                          >
                            {rating}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage fontSize={"13pt"} colorScheme="red">
                      {formik.errors.overallRating}
                    </FormErrorMessage>
                  </FormControl> */}
                  {/* Recommendation */}
                  <FormControl
                    mt={6}
                    isInvalid={
                      formik.errors.recommend && formik.touched.recommend
                    }
                  >
                    <div>
                      <Text fontSize="3xl" fontWeight={500}>
                        Would You Recommend Us Based on Your Ratings Above?
                      </Text>
                      <Text fontSize={"16pt"}>
                        {" "}
                        Would you recommend Litmus Services Limited to other
                        healthcare facilities/nursing homes based on your
                        experience?
                      </Text>
                    </div>
                    <RadioGroup
                      value={formik.values.recommend}
                      color={formik.errors.recommend && `red`}
                    >
                      <Stack direction={["column", "row"]}>
                        {["Yes", "No", "Maybe"].map((option) => (
                          <Radio
                            name="recommend"
                            onChange={formik.handleChange}
                            fontSize={"16pt"}
                            key={option}
                            value={option}
                          >
                            {option}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                    <FormErrorMessage fontSize={"13pt"} colorScheme="red">
                      {formik.errors.recommend}
                    </FormErrorMessage>
                  </FormControl>

                  <div>
                    <CustomButton
                      type={"submit"}
                      colorScheme="blue"
                      label={" Submit"}
                    />
                  </div>
                </Stack>
              </>
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default FeedbackForm;

// 'use strict'
import React from "react";
import {
  Stack,
  Box,
  Text,
  Button,
  Image,
  useColorModeValue,
  AbsoluteCenter,
  Container,
  SimpleGrid,
  Flex,
  Heading,
} from "@chakra-ui/react";
import GeneralLayout from "./layout/GeneralLayout";
import { Hero } from "./components/Hero";
import { CustomButton } from "./components/Buttons";
import { Link } from "react-router-dom";
import { clientele, whyus } from "./util/data";
import WhyLitmus from "./components/WhyLitmus";
import ArrowDown from "./components/ArrowDown";
import { motion } from "framer-motion";
export default function Home() {
  return (
    <GeneralLayout
      pageContent={
        <>
          <Hero
            videURL="/video/intro.mp4"
            // videURL="https://videocdn.cdnpk.net/videos/19f17f4b-94e8-4d18-a03a-1897937ffbcb/horizontal/previews/clear/large.mp4?token=exp=1721376354~hmac=ccc6cb1e363e5e2bf19d8a4e35d7c81ae4ecbc22492451b8c1a4968ba9c07827"
            heroTitle="Welcome to Litmus Services Ltd."
            heroDescription="Litmus Services is a brand that provides high-quality and efficient services that constantly meet the needs of our clients across sectors within the United Kingdom."
            // heroDescription="A reliable nationwide care service provider and multisector recruitment company committed to the delivery of safe, effective and high-quality services."
            // heroLead="  . . . partnership that works"
            heroActionButton={
              <div className="w-full">
                <div className="flex flex-col  md:flex-row md:space-x-5 gap-3 items-center content-center justify-center">
                  <a
                    className="text-base bg-yellow-400  text-black flex-none px-5 rounded-lg py-4 md:w-3/12 w-full hover:bg-yellow-500
                  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                    href="/care"
                  >
                    Litmus Care
                  </a>
                  <a
                    href="/recruitment"
                    className="text-base bg-blue-400  text-black flex-none px-5 rounded-lg py-4 md:w-3/12 w-full hover:bg-blue-500
                  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                  >
                    Litmus Recruitment
                  </a>
                </div>

                <div className="flex justify-center items-center absolute left-[50%]  md:bottom-10 bottom-5">
                  <svg
                    className="w-10 h-10 text-white-600 animate-bounce"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
            }
          />

          <div
            style={{
              backgroundImage: "url('/images/career.jpg')",
              backgroundRepeat: "no-repeat",
              // backgroundPosition: "fixed",
              backgroundSize: "cover",
              objectFit: "cover",
              backgroundPositionY: "fixed",
            }}
            className="hover:transition-all"
          >
            <div
              className="flex flex-col h-[auto] justify-center items-center gap-24 md:py-24 md:px-32 px-5 py-24 w-full "
              style={{
                background: "rgba(255, 255, 255,0.7)",
              }}
            >
              <h2 className="text-4xl sm:text-6xl md:text-7xl text-balance  font-bold tracking-tight text-black ">
                WHAT WE DO
              </h2>

              {/* Navigation Tab Link */}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-5  lg:max-w-5xl xl:max-w-7xl w-full">
                <div
                  className="  bg-gradient-to-tr from-blue-800 to-violet-900 hover:to-blue-900 hover:from-violet-800  rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl hover:bg-violet-800 
                transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                >
                  <div>
                    <span class="inline-flex items-center justify-center p-2 bg-blue-500 rounded-md shadow-lg ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#e8eaed"
                      >
                        <path d="M420-280h120v-100h100v-120H540v-100H420v100H320v120h100v100ZM160-120v-480l320-240 320 240v480H160Zm80-80h480v-360L480-740 240-560v360Zm240-270Z" />
                      </svg>
                    </span>
                  </div>
                  <h3 class=" text-white mt-5 text-3xl font-bold tracking-tight ">
                    Litmus Care
                  </h3>
                  <p class="text-blue-100  mt-2 text-[12pt]">
                    Litmus Care is a CQC registered domiciliary care service
                    provider of person-centred, high-quality, compassionate and
                    safe care services to support service users with day-to-day
                    tasks that through incapacity or illness can no longer be
                    performed safely or independently, in a way that promotes
                    their dignity, privacy and independence.
                  </p>
                  <span className="inline-flex items-center justify-center   rounded-md shadow-lg">
                    <a
                      href="/care"
                      className="text-base  bg-yellow-400  text-black flex-none px-5 rounded-lg py-2 hover:bg-yellow-500
                      
                      "
                    >
                      Learn More
                    </a>
                  </span>
                </div>
                <div
                  className="bg-white bg-gradient-to-tr from-blue-700 to-blue-900 hover:to-blue-700 hover:from-blue-900 rounded-lg px-6 py-8 ring-1 space-y-5 ring-blue-900/5 shadow-xl
                transition ease-in-out delay-150  hover:-translate-y-2 hover:scale-101 duration-300  "
                >
                  <div>
                    <span class="inline-flex items-center justify-center p-2 bg-blue-500 rounded-md shadow-lg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#e8eaed"
                      >
                        <path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" />
                      </svg>
                    </span>
                  </div>
                  <h3 class="text-white mt-5 text-3xl font-bold tracking-tight">
                    Litmus Recruitment
                  </h3>
                  <p class="text-blue-100  mt-2 text-[12pt]">
                    We specialize in the recruitment and deployment of agency
                    healthcare professionals across diverse care settings,
                    including nursing homes, residential care facilities,
                    hospices, hospitals etc. We provide flexible staffing
                    options tailored to the specific needs of each healthcare
                    facility, including short-term coverage, long-term
                    placements, and emergency staffing solutions.
                  </p>
                  <span className="inline-flex items-center justify-center   rounded-md shadow-lg">
                    <a
                      href="/recruitment"
                      className="text-base  bg-blue-500  text-black flex-none px-5 rounded-lg py-2 "
                    >
                      Learn More
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <WhyLitmus />
          {/* <div className="w-[100%] m-auto justify-center items-center py-20 space-y-10 bg-blue-500">
            <h2 className="text-4xl text-balance md:text-7xl font-bold text-center">
              OUR CLIENTELE
            </h2>
            <p className="text-[24px] text-center text-balance">
              Litmus Services Limited serves a diverse clientèle of healthcare
              facilities, including:
            </p>
            <div className="flex flex-col md:flex-row w-[80%] m-auto justify-center items-center">
              <div className="flex-none">
                <img
                  alt=""
                  className="m-auto w-[500px] "
                  src="/images/our-clientele.png"
                />
              </div>

              <div className="flex-1">
                <div className="flex flex-wrap gap-2 justify-center items-center text-balance md:w-[80%] m-auto  ">
                  {clientele &&
                    clientele.map((client, index) => (
                      <p
                        className={
                          `w-auto p-1 text-[30px] font-bold border-white border-4 text-white text-center ` +
                          client.color
                        }
                      >
                        {client.client}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          </div> */}

          <div
            m={"auto"}
            className="flex flex-col-reverse lg:flex-row py-24 md:py-32 mx-auto md:max-w-5xl px-5 gap-5"
          >
            {/* Navigation Tab Link */}

            <div className="space-y-8 flex flex-col justify-center content-center flex-1">
              <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl">
                24/7 SUPPORT
              </h2>
              <p className="text-left text-balance text-[12pt] font-normal">
                We have a dedicated team that is available round-the-clock to
                provide support and assistance to both clients and staff,
                ensuring prompt resolution of any staffing challenges or
                concerns promptly.
              </p>

              <span className="inline-flex  ">
                <a
                  href="/contact"
                  className="text-base   shadow-lg  bg-blue-500  text-black flex-none px-5 rounded-lg py-2 "
                >
                  Contact us now
                </a>
              </span>
            </div>
            <div className="flex-1">
              <Image
                className="w-full max-w-none rounded-xl"
                src="/images/recruitment.jpg"
              />
            </div>
          </div>
          {/* <div className=" justify-center">
            <div
              m={"auto"}
              className="flex flex-col-reverse lg:flex-row py-24 md:py-32 mx-auto max-w-7xl px-5 gap-5 "
            >
             

              <div className="space-y-8 flex flex-col justify-center content-center flex-1">
                <h2 className="text-4xl font-bold tracking-tight text-black sm:text-6xl text-center">
                  Contact Us
                </h2>
                <p className="text-center text-balance text-[12pt] font-normal">
                  Litmus Care recognises the importance of gaining insights into
                  home care before making any decisions. If you would like to
                  have an informal conversation about how Litmus Care can assist
                  you, please do reach out to us NOW.
                </p>
                <span className="inline-flex items-center justify-center   ">
                  <a
                    href="/contact"
                    className="text-base  shadow-lg  bg-blue-500  text-black flex-none px-5 rounded-lg py-2 "
                  >
                    Contact us now
                  </a>
                </span>
              </div>
            </div>
          </div> */}
        </>
      }
    />
  );
}
// Litmus Care recognises the importance of gaining insights into home care before making any decisions. If you would like to have an informal conversation about how Litmus Care can assist you, please do reach out to us NOW.

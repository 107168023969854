import React, { useCallback, useEffect, useState } from "react";
import { API_BASE_URL } from "../components/APIs";
import axios from "axios";
import PdfAsImageIframe from "./PDFtoImage";
import Test from "./PdftoImage2";
import { Link } from "react-router-dom";
import FileViewer from "./FileViewer";

const PersonalDetails = ({ user_id }) => {
  const containerStyle = {
    width: "600px",
    margin: "0 auto",
    // paddingTop: '.2em',
    paddingBottom: "1.5em",
    paddingLeft: "1.5em",
    paddingRight: "1.5em",
    fontFamily: "Arial, sans-serif",
    "page-break-after": "always",
    boxShadow: "lg",
  };
  const [userDetails, setUserDetails] = useState();
  const [referee, setRef] = useState([]);
  const [isLoading, setLoading] = useState();
  let num = 0;
  const getRef = useCallback(async () => {
    try {
      const url = API_BASE_URL + "application/getRef";

      const response = await axios.post(url, { user_id: user_id });

      setRef(response?.data);
      // setUser(response?.data);

      //   localStorage.setItem("userInfo", JSON.stringify(response?.data));
      //   Cookies.set("getUserInfo", true);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching user details:", error);
    }
  }, [user_id]);
  const getUsersInfo = useCallback(async () => {
    try {
      setLoading(true);
      const url = API_BASE_URL + "application/getAllInfo";

      const response = await axios.post(url, { user_id: user_id });

      setUserDetails(response?.data);
      // setUser(response?.data);

      //   localStorage.setItem("userInfo", JSON.stringify(response?.data));
      //   Cookies.set("getUserInfo", true);
      setLoading(false);
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  }, []); // Empty dependency array ensures the function reference doesn't change
  useEffect(() => {
    getUsersInfo();
    getRef();
  }, [getUsersInfo, getRef]);

  return (
    <div style={containerStyle}>
      <div className="font-normal text-[9px] flex flex-col space-y-3 text-left  ">
        <p className="text-lg font-bold text-center mt-5">
          Application form for {userDetails?.fname} {userDetails?.lname}
          <br /> Position:{userDetails?.job_title}
        </p>

        <table>
          <tr className="font-bold">
            <td>(Title) First name:</td>
            <td>Last name:</td>
            <td>Other name:</td>
          </tr>
          <tr>
            <td>
              {" "}
              {userDetails?.title} {userDetails?.fname}
            </td>
            <td> {userDetails?.lname}</td>
            <td> {userDetails?.oname}</td>
          </tr>
          {/*  */}
          <tr className="font-bold">
            <td>Gender:</td>
            <td>Date of Birth:</td>
            <td>Marital Status:</td>
          </tr>
          <tr>
            <td>{userDetails?.gender}</td>
            <td> {userDetails?.dob}</td>
            <td> {userDetails?.nationality}</td>
          </tr>
          <tr className="font-bold">
            <td>Email Address:</td>
            <td>Phone Number:</td>
            <td>Nationality:</td>
          </tr>
          <tr>
            <td> {userDetails?.email}</td>
            <td> {userDetails?.phone_number}</td>
            <td> {userDetails?.nationality}</td>
          </tr>
          <tr className="font-bold">
            <td>Have access to own car?</td>
            <td>Nature of Driving Licence</td>
          </tr>
          <tr>
            <td colSpan={3}>
              {" "}
              <h1 className=" font-bold underline text-sm">
                Address information
              </h1>
            </td>
          </tr>
          <tr className="font-bold">
            <td>House Number:</td>
            <td>Address Line:</td>
            <td>Town/City:</td>
          </tr>
          <tr>
            <td> {userDetails?.house_number}</td>
            <td> {userDetails?.address_line1}</td>
            <td> {userDetails?.town_city}</td>
          </tr>
          {/*  */}
          <tr className="font-bold">
            <td>County:</td>
            <td>Country:</td>
            <td>Post Code:</td>
          </tr>
          <tr>
            <td> {userDetails?.county}</td>
            <td> {userDetails?.country}</td>
            <td> {userDetails?.post_code}</td>
          </tr>
          <tr className="font-bold">
            <td colSpan={3}>Proof of address link:</td>
          </tr>
          <tr>
            <td colSpan={3}>
              {" "}
              <FileViewer
                fileUrl={API_BASE_URL + "uploads/" + userDetails?.poa_file}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              {" "}
              <h1 className="text-sm underline font-bold">
                Next of Kin information
              </h1>
            </td>
          </tr>
          <tr className="font-bold">
            <td>First name:</td>
            <td>Last name:</td>
            <td>Email:</td>
          </tr>
          <tr>
            <td> {userDetails?.nok_fname}</td>
            <td> {userDetails?.nok_lname}</td>
            <td> {userDetails?.nok_email}</td>
          </tr>
          {/*  */}
          <tr className="font-bold">
            <td>Mobile:</td>

            <td>Relationship with Next of Kin:</td>
            <td>Post Code:</td>
          </tr>
          <tr>
            <td> {userDetails?.nok_mobile}</td>
            <td> {userDetails?.nok_relationship}</td>
            <td> {userDetails?.nok_postcode}</td>
          </tr>
          <tr className="font-bold">
            {" "}
            <td colSpan={2}>Address:</td>
          </tr>
          <tr>
            {" "}
            <td colSpan={2} className="">
              {" "}
              {userDetails?.nok_address}
            </td>
          </tr>
          <tr>
            {" "}
            <td>
              <h1 className="font-bold text-sm underline ">
                Proof of Identity
              </h1>
            </td>
          </tr>
          <tr className="font-bold">
            <td colSpan={3}>Proof Type: {userDetails?.poi_type}</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <FileViewer
                fileUrl={API_BASE_URL + "uploads/" + userDetails?.poi_file}
              />
            </td>
          </tr>
          <tr>
            {" "}
            <td>
              <h1 className="font-bold text-sm underline ">Right to Work</h1>
            </td>
          </tr>
          <tr className="font-bold">
            <td>Visa Type:</td>

            <td>Documnet Number:</td>
            <td>Share Code:</td>
          </tr>
          <tr>
            <td> {userDetails?.visa_type}</td>
            <td> {userDetails?.visa_number}</td>
            <td> {userDetails?.share_code}</td>
          </tr>
          <tr className="font-bold">
            <td>Issue date:</td>

            <td>Expiry date:</td>
          </tr>
          <tr>
            <td> {userDetails?.visa_issue_date}</td>
            <td> {userDetails?.visa_expiry_date}</td>
          </tr>
          <tr>
            <td>Document Link:</td>
          </tr>
          <tr>
            <td colSpan={3}>
              {" "}
              <FileViewer
                fileUrl={API_BASE_URL + "uploads/" + userDetails?.visa_file}
              />{" "}
            </td>
          </tr>{" "}
          <tr>
            <td>
              <h1 className="font-bold text-sm underline ">Resume</h1>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              {" "}
              <FileViewer
                fileUrl={API_BASE_URL + "uploads/" + userDetails?.resume_file}
              />{" "}
            </td>
          </tr>
          {/* <tr>
            <td colSpan={3}>
              {" "}
              <h1 className=" font-bold underline text-sm uppercase">
                Employment Details
              </h1>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>Date of appointment</td>
          </tr>
          <tr>
            <td>{userDetails?.date_of_appointment}</td>
          </tr>
          <tr>
            <td>Employment statement</td>
          </tr>
          <tr>
            <td colSpan={3}>{userDetails?.employment_statement}</td>
          </tr>
          <tr>
            {" "}
            <td>National Insurance number</td>
          </tr>
          <tr>
            {" "}
            <td>{userDetails?.national_insurance_number}</td>
          </tr> */}
          {/* <tr>
            <td colSpan={3}>
              {" "}
              <h1 className=" font-bold underline text-sm uppercase">
                Banking Details
              </h1>
            </td>
          </tr> */}
{/*          
          <tr>
            <td>Banker:</td>
            <td>Account Number</td>
            <td>Sort Code</td>
          </tr> */}
          {/* <tr>
            <td>{userBank?.banker}</td>
            <td>{userBank?.account_number}</td>
            <td>{userBank?.sort_code}</td>
          </tr>
          <tr>
            <td>Building Society Ref.:</td>
            <td>Branch</td>
            <td>Reference number</td>
          </tr>
          <tr>
            <td>{userDetails?.bank_details}</td>
            <td>{userBank?.branch}</td>
            <td>{userBank?.reference}</td>
          </tr> */}
          <tr>
            <td colSpan={3}>
              {" "}
              <h1 className=" font-bold underline text-sm uppercase">
                Referee Details
              </h1>
            </td>
          </tr>
          {referee.map((ref, index) => {
            const refResponse =
              !ref.ref_response || ref.ref_response === "null"
                ? null
                : JSON.parse(ref.ref_response); // Parse the nested ref_response object

            return (
              <React.Fragment key={index}>
                <tr>
                  <td colSpan={3} className="underline font-bold">
                    Referee {index + 1}
                  </td>
                </tr>
                <tr className="font-bold">
                  <td>Referee Name</td>
                  <td>Referee Email</td>
                  <td>Referee Mobile</td>
                </tr>
                <tr>
                  <td>
                    {ref.ref_fname} {ref.ref_lname}
                  </td>
                  <td>{ref.ref_email}</td>
                  <td>{ref.ref_mobile}</td>
                </tr>
                <tr>
                  <td colSpan={3} className="font-bold">
                    Referee Response
                  </td>
                </tr>
                <tr className="font-light">
                  <td>{!refResponse && "No Response"}</td>
                </tr>
                <tr className="font-light">
                  <td>Organisation</td>
                  <td>{refResponse.organisation}</td>
                </tr>
                <tr className="font-light">
                  <td>Referee Position</td>
                  <td>{refResponse.ref_position}</td>
                </tr>
                <tr className="font-light">
                  <td>Start Date</td>
                  <td>{refResponse.start_from}</td>
                </tr>
                <tr className="font-light">
                  <td>End Date</td>
                  <td>{refResponse.till}</td>
                </tr>
                <tr className="font-light">
                  <td>Why Left Employment</td>
                  <td>{refResponse.why_leave_employment}</td>
                </tr>
                <tr className="font-light">
                  <td>Rehire?</td>
                  <td>{refResponse.employ_again}</td>
                </tr>
                <tr className="font-light">
                  <td>Physical Illness?</td>
                  <td>{refResponse.physical_illness}</td>
                </tr>
                <tr className="font-light">
                  <td>Criminal Record?</td>
                  <td>{refResponse.criminal_record}</td>
                </tr>
                <tr className="font-light">
                  <td>Communication Skills</td>
                  <td>{refResponse.candidate_communication}</td>
                </tr>
                <tr className="font-light">
                  <td>Punctuality</td>
                  <td>{refResponse.candidate_punctuality}</td>
                </tr>
                <tr className="font-light">
                  <td>Conduct</td>
                  <td>{refResponse.candidate_conduct}</td>
                </tr>
                <tr className="font-light">
                  <td>Reliability</td>
                  <td>{refResponse.candidate_reliability}</td>
                </tr>
                <tr className="font-light">
                  <td>Suitability</td>
                  <td>{refResponse.candidate_suitability}</td>
                </tr>
                {/* Add more fields as needed */}
              </React.Fragment>
            );
          })}
          <tr>
            <td colSpan={3}>
              {" "}
              <h1 className="text-sm underline font-bold">
                Declaration under the Protection of Freedoms Act 2012
              </h1>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              The post for which you are applying is a ‘regulated activity’
              within the meaning of Part 5 Chapter 1 of the Protection of
              Freedoms Act 2012 and it is a criminal offence for a ‘barred
              person’ to apply to work in a regulated activity. If you are a
              barred person you must not proceed with this job application.
              <br />
              If your application is successful you will be required to
              co-operate with us in obtaining a disclosure of criminal
              convictions and in checking your barred status with the Disclosure
              and Barring Service.
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="font-bold">
              Are you a barred person?{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>No </td>
          </tr>
          <tr>
            <td colSpan={3} className="font-bold">
              Do you have any outstanding safeguarding investigations or
              suspensions, criminal prosecutions or convictions that might lead
              to your being barred from working on child-orientated premises
              (e.g. schools), with children or with protected adults?
            </td>
          </tr>
          <tr>
            <td colSpan={3}>No </td>
          </tr>
          <tr>
            <td colSpan={3} className="font-bold">
              Do you have any convictions, cautions, reprimands or final
              warnings that would not be filtered in line with current guidance?
              See
              https://www.gov.uk/tell-employer-or-college-about-criminal-record
              for further guidance on how filtering applies.
            </td>
          </tr>
          <tr>
            <td colSpan={3}>No </td>
          </tr>
          <tr>
            <td colSpan={2} className="font-bold">
              Signed {userDetails?.fname} {userDetails?.lname}
            </td>
            <td>Date {userDetails?.date_of_appointment}</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PersonalDetails;

"use client";

import {
  Heading,
  Text,
  Stack,
  Box,
  useToast,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Alert,
} from "@chakra-ui/react";

import GeneralLayout from "../../layout/GeneralLayout";
import * as Yup from "yup";
import { CustomButton } from "../../components/Buttons";
import { Hero } from "../../components/Hero";
import { CustomInput, CustomTextarea } from "../../components/Input";
import { FancySidebar } from "../../layout/component/FancySidebar";
import { useFormik } from "formik";
import axios from "axios";
import { API_BASE_URL } from "../../components/APIs";
import { useState } from "react";

export default function CareContact() {
  const FancyNavItems = [
    { label: "Live-in Care", link: "/healthcare/live-in-care" },
    { label: "Domiciliary Care", link: "/healthcare/domiciliary" },
    { label: "Job Oppurtunities", link: "/career" },
  ];
  const toast = useToast();
  const [submited, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      other_query: "",
      service_required: "",
    },
    validationSchema: Yup.object({
      contact_name: Yup.string().required("Please enter contact phone number"),
      contact_email: Yup.string().required("Please enter contact Eemail address"),
      contact_phone: Yup.string().required("Please enter contact phone number"),
      other_query: Yup.string().required("Any other information that might be of help to understand you need"),
      service_required: Yup.string().required("Please let us kno the services you need from us"),
    }),
    onSubmit: (data) => {
      setLoading(true);
      console.log(data);
      // alert(data);
      axios
        .post(API_BASE_URL + "sendAdminEmail", data)
        .then((response) => {
          toast({
            title: "Message Sent",
            description: response?.data.message,
            status: response?.data.status,
            position: "top",
            isClosable: true,
          });
          setSubmitted(true);
          setLoading(false);
        })

        .catch((error) => {
          toast({
            title: "Message Sent Failure",
            description: error?.data.message,
            status: "error",
            position: "top",
            isClosable: true,
          });
          setLoading(false);
        });
    },
  });
  return (
    <GeneralLayout
      pageContent={
        <>
          <Hero
            imageURL="/images/bg-hero.jpg"
            heroTitle="Get Started"
            heroDescription="Please fill the form below to help us know how we can help you with your needs"
            heroActionButton={
              <div className="flex justify-center items-center absolute left-[50%]  md:bottom-10 bottom-5">
                <svg
                  className="w-10 h-10 text-white-600 animate-bounce"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            }
          />
          <div className="flex py-20" width={"full"}>
            <Stack
              justifyContent={"flex-start"}
              direction={{ base: "column", md: "row" }}
              position={"relative"}
              display={"flex"}
              w={{ base: "100%", md: "85%", sm: "100%" }}
              m={"auto"}
              spacing={10}
            >
              <Box className="" width={"full"} py={10} px={{ base: 0, md: 10 }}>
                {submited ? (
                  <>
                    <Alert
                      gap={5}
                      rounded={"xl"}
                      top={"20"}
                      width={["full", "100%"]}
                      mx={"auto"}
                      status="success"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      py={10}
                      // height="200px"
                    >
                      <AlertIcon boxSize="60px" />
                      <AlertTitle
                        mt={4}
                        mb={4}
                        fontSize={["28pt", "32pt"]}
                        lineHeight={["30pt", "42pt"]}
                      >
                        Thank you for reaching Out!
                      </AlertTitle>
                      <AlertDescription
                        maxWidth="lg"
                        fontSize={"16pt"}
                        lineHeight={"18pt"}
                      >
                        Our team will get back to you within 24hrs.
                      </AlertDescription>

                      <CustomButton
                        label={"Send another message"}
                        as={"a"}
                        py={5}
                        colorScheme={"blue"}
                        onClick={() => setSubmitted(false)}
                      />
                    </Alert>
                  </>
                ) : (
                  <>
                    {" "}
                    <form
                      className="max-w-5xl mx-auto "
                      as={"form"}
                      spacing={5}
                      bg={"gray.50"}
                      py={10}
                      px={{ base: 5, md: 10 }}
                      // method="get"
                      // action="https://litmusapi.litmusservices.co.uk/sendAdminEmail"
                      onSubmit={formik.handleSubmit}
                    >
                      <Heading>Request Form</Heading>
                      <CustomInput
                        type="text"
                        placeholder="Which of our services you are intrested in"
                        name="service"
                        padding={"30"}
                        isInvalid={formik.errors.service_required && formik.touched.service_required}
                        onChange={formik.handleChange}
                        value={formik.values.service_required}
                        formErroMsg={formik.errors.service_required}
                      />
                      <CustomInput
                        type="text"
                        placeholder="Enter Contact name"
                        name="contact_name"
                        padding={"30"}
                        isInvalid={formik.errors.contact_name && formik.touched.contact_name}
                        onChange={formik.handleChange}
                        value={formik.values.contact_name}
                        formErroMsg={formik.errors.contact_name}
                      />
                      <CustomInput
                        type="email"
                        placeholder="Enter contact Email Address"
                        name="contact_email"
                        padding={"30"}
                        isInvalid={formik.errors.contact_email && formik.touched.contact_email}
                        onChange={formik.handleChange}
                        value={formik.values.contact_email}
                        formErroMsg={formik.errors.contact_email}
                      />
                      <CustomInput
                        type="text"
                        placeholder="Enter Contact Phone Number"
                        name="contact_phone"
                        padding={"30"}
                        isInvalid={
                          formik.errors.contact_phone &&
                          formik.touched.contact_phone
                        }
                        onChange={formik.handleChange}
                        value={formik.values.contact_phone}
                        formErroMsg={formik.errors.contact_phone}
                      />

                      <CustomTextarea
                        type="text"
                        placeholder="Any Other information"
                        name="other_query"
                        padding={"30"}
                        isInvalid={
                          formik.errors.other_query && formik.touched.other_query
                        }
                        onChange={formik.handleChange}
                        value={formik.values.other_query}
                        formErroMsg={formik.errors.other_query}
                      />
                      <Box className="py-5" justifySelf={"flex-end"}>
                        <CustomButton
                          type="submit"
                          colorScheme="blue"
                          label="Submit"
                        />
                      </Box>
                    </form>
                  </>
                )}
              </Box>
            </Stack>
          </div>
        </>
      }
    />
  );
}

import {
  Box,
  Flex,
  Grid,
  // HStack,
  Heading,
  Highlight,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { Hero } from "../../components/Hero";
import { CustomButton } from "../../components/Buttons";
import AnimatedBox from "../../components/AnimatedBox";
import QQualityTag from "../../components/QQualityTag";

import GeneralLayout from "../../layout/GeneralLayout";

const LitmusHealthcare = () => {
  // const hover = {
  //   // backgroundColor: 'blue.400',
  //   color: "blue.400",
  //   transform: "translateX(5px)",
  // };
  // const buttonTransition =
  //   '"background-color 0.3s, color 0.3s, transform 0.3s"';
  const services = [
    {
      title: "Personal care",
      image: "/images/PersonalCare.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Care and support for adults over 65 yrs",
      image: "/images/support_over65yrs.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Caring and support for adults under 65 yrs",
      image: "/images/care-for-young-under-65years.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Care and support for children and young people",
      image: "/images/care-for-young.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Dementia care",
      image: "/images/dementia-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Eating disorders Care",
      image: "/images/eating-disorder.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Mental health conditions",
      image: "/images/mental-disorder.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Physical disabilities care and support",
      image: "/images/disabilities.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Sensory impairments care and support",
      image: "/images/sensory.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Substance misuse problems support",
      image: "/images/substance-misuse.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Multiple Sclerosis care",
      image: "/images/Multiple-Sclerosis.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Complex care and support",
      image: "/images/complex-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Rehabilitation and Reablement",
      image: "/images/reablement.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "End of life care",
      image: "/images/eol-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Companionship",
      image: "/images/companionship.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Respite care",
      image: "/images/respite-care.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Errand services",
      image: "/images/errand-services.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Housekeeping",
      image: "/images/housekeeping.jpg",
      link: "#", // Provide the image URL
    },
    {
      title: "Medication Support",
      image: "/images/medication-support.jpg",
      link: "#", // Provide the image URL
    },
    // Add more services here...
  ];
  // const IamLookinFor = [
  //   {
  //     title: "Live-in Care",
  //     image: "/images/litmus-bg.jpg",
  //     description: "", // Provide the image URL
  //   },
  //   {
  //     title: "Domiciliary",
  //     image: "/images/litmus-bg.jpg",
  //     description: "", // Provide the image URL
  //   },
  // Add more services here...
  // ];
  return (
    <GeneralLayout
      hasHero={true}
      page_type="care"
      pageContent={
        <>
          <Box w={"full"}>
            <Hero
              videURL="/video/litmus.mp4"
              heroTitle="Litmus Care"
              heroDescription=" We are here to provide you with the support and care you need at
            the comfort of your home as your dependable partner in progress"
              heroLead="  . . . partnership that works"
              heroActionButton={
                <div className="w-full">
                  <div className="flex flex-col  md:flex-row md:space-x-5 gap-3 items-center content-center justify-center">
                    <a
                      className="text-base bg-yellow-400  text-black flex-none px-5 rounded-lg py-4 md:w-3/12 w-full hover:bg-yellow-500
                  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                      href="/care/live-in-care"
                    >
                      Live-in Care
                    </a>
                    <a
                      href="/care/domiciliary"
                      className="text-base bg-blue-400  text-black flex-none px-5 rounded-lg py-4 md:w-3/12 w-full hover:bg-blue-500
                  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                    >
                      Domiciliary Care
                    </a>
                  </div>

                  <div className="flex justify-center items-center absolute left-[50%]  md:bottom-10 bottom-5">
                    <svg
                      className="w-10 h-10 text-white-600 animate-bounce"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              }
            />
            <Stack
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={50}
              gap={0}
              direction={{ base: "column", md: "row" }}
            >
              <Box
                flex={"1"}
                alignContent={"center"}
                justifyContent={"center"}
                bg={"red"}
                borderTopLeftRadius={"20"}
                borderBottomLeftRadius={{ base: "", md: "20" }}
                borderTopRightRadius={{ base: "20", md: "0" }}
                backgroundImage={
                  'url("https://img.freepik.com/premium-photo/nurse-doctor-senior-care-caregiver-help-assistence-retirement-home-nursing-elderly-man-woman-health-support-african-american-black_772720-5899.jpg?w=740")'
                }
                backgroundPosition={"center"}
                backgroundSize={"cover"}
                minHeight={{ base: "300px", md: "auto" }}
              >
                <Image
                  borderTopRightRadius={{ base: "20", md: "0" }}
                  borderTopLeftRadius={"20"}
                  position={"static"}
                  w={"full"}
                  src=""
                  alt="Dan Abramov"
                />
              </Box>
              <Box
                flex="1.5"
                bg={useColorModeValue("blue.400", "gray.800")}
                color={"white"}
                w={{ base: "100%", md: "70%" }}
                borderTopRightRadius={{ base: "", md: "20" }}
                borderBottomLeftRadius={{ base: "20", md: "0" }}
                borderBottomRightRadius={{ base: "20", md: "20" }}
              >
                <Stack gap={10} py={8} px={"6"}>
                  <Heading
                    fontSize={{ base: "3xl", md: "5xl" }}
                    lineHeight={"50px"}
                  >
                    Welcome to Litmus Care
                    <Text
                      fontSize={{ base: "20px", md: "20px" }}
                      fontStyle={"italic"}
                    >
                      <Highlight
                        query="... partnership that works"
                        styles={{
                          px: "2",
                          py: "1",
                          rounded: "full",
                          bg: "yellow.200",
                        }}
                      >
                        ... partnership that works
                      </Highlight>
                    </Text>
                  </Heading>

                  <Text
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight={"normal"}
                    textAlign={"justify"}
                    pr={"30"}
                  >
                    Our company provides person-centered care and support
                    services to our clients. We are proud of a team of well
                    trained and compassionate staff who are always ready and
                    happy to join you to assess, create and deliver a care plan
                    tailored specifically for you.
                    <br />
                  </Text>
                  <Text
                    fontSize={{ base: "16px", md: "20px" }}
                    fontWeight={"normal"}
                    pr={"30"}
                  >
                    For further information, please do get in touch with our
                    friendly team
                  </Text>
                  <Stack direction="row" spacing={4}>
                    {/* <Button
                leftIcon={<EmailIcon />}
                colorScheme="blue"
                variant="solid"
                size={'lg'}
              >
                Email
              </Button> */}
                    <CustomButton
                      label="Contact Us"
                      bg={"yellow.400"}
                      as={"a"}
                      link="/contact"
                    />
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            {/* <div className="">
              <p className="text-center text-4xl font-medium">
                {" "}
                I'm Looking for
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3  py-20 px-3 text-center gap-5">
                <Flex
                  position={""}
                  bg="blue.200"
                  height="450px"
                  p={5}
                  alignContent={"end"}
                  textAlign={"center"}
                  rounded={10}
                  backgroundImage={
                    "url('/images/adulst-support-over-65years.jpg')"
                  }
                  backgroundPosition={"center"}
                  bgRepeat={"no-repeat"}
                  backgroundSize={"cover"}
                  className="flex flex-col"
                >
                  <Heading
                    // fontSize={"34pt"}
                    pos={""}
                    alignSelf={"end"}
                    bottom={"1em"}
                    color={"white"}
                    textShadow="2px 2px 5px black"
                    className="text-center"
                  >
                    Job Opportunities
                  </Heading>
                  <a
                    className="text-base bg-yellow-400  text-black flex-none px-5 rounded-lg py-4 md:w-11/12 w-full hover:bg-yellow-500
                  transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300"
                    href="/care"
                  >
                    Litmus Care
                  </a>
                </Flex>
              </div>
            </div> */}

            <Stack
              id="s"
              w={{ base: "100%", md: "100%" }}
              m={"auto"}
              py={"20"}
              gap={5}
              bg={useColorModeValue("white", "blue.900")}
            >
              <Box w={"80%"} m={"auto"}>
                <Heading
                  // fontWeight={500}
                  fontSize={{ base: "4xl", md: "5xl" }}
                  color={"gray.800"}
                  textAlign={{ base: "center", md: "center" }}
                >
                  Our Services
                </Heading>
                <Text
                  w={["100%", "60%"]}
                  mx={"auto"}
                  mt={5}
                  color={"gray.800"}
                  fontSize={{ base: "16pt", md: "20pt" }}
                  textAlign={{ base: "center", md: "center" }}
                >
                  Litmus Care provides safe, compassionate and quality
                  domiciliary and live-in care services for people in need of
                  the following:{" "}
                </Text>
                <Stack py={"10"}>
                  {" "}
                  <Grid
                    templateColumns={{
                      base: "1fr",
                      md: "repeat(3, 1fr)",
                    }}
                    gap={10}
                  >
                    {services.map((service, index) => (
                      <Box
                        key={index}
                        rounded="xl"
                        border={"2px solid #0074D9"}
                        bg={"blue.50"}
                        justifyContent={"center"}
                        alignContent={"center"}
                        alignItems={"center"}
                        href={service.link}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2, duration: 0.5 }}
                      >
                        <Image
                          roundedTop={"xl"}
                          style={{
                            width: "100%",
                            height: "700px",
                            objectFit: "cover",
                          }}
                          src={service.image}
                          alt={service.title}
                        />

                        <Stack p={4} spacing={3}>
                          <Text
                            fontSize={"3xl"}
                            fontWeight={400}
                            textAlign={"center"}
                            color={"gray.800"}
                            mb={2}
                          >
                            {service.title}
                          </Text>
                          {/* <Text>Description of Services</Text>
                          <Box>
                            <CustomButton
                              bg={"blue.400"}
                              label={"Learn More"}
                            />
                          </Box> */}
                        </Stack>
                      </Box>
                    ))}
                  </Grid>
                </Stack>
              </Box>
            </Stack>
            <Box
              w={{ base: "93%", md: "80%" }}
              m={"auto"}
              py={"20"}
              gap={5}
              justifyContent={"space-between"}
              alignContent={"space-between"}
            >
              {/* <Stack
                direction={{ base: "column-reverse", lg: "row" }}
                spacing={{ base: "0", lg: "20" }}
              >
                <Box
                  width={{ lg: "60%" }}
                  transform={{ base: "translateY(-50%)", lg: "none" }}
                  bg={{
                    base: useColorModeValue("red.50", "gray.700"),
                    lg: "transparent",
                  }}
                  alignSelf={"center"}
                  justifySelf={"center"}
                  mx={{ base: "6", md: "8", lg: "0" }}
                  px={{ base: "6", md: "8", lg: "0" }}
                  py={{ base: "6", md: "8", lg: "12" }}
                >
                  <Stack spacing={{ base: "8", lg: "10" }}>
                    <Stack spacing={{ base: "2", lg: "4" }}>
                      <Heading fontSize={{ base: "3xl", md: "5xl" }}>
                        Live-in Care
                      </Heading>
                      <Text
                        fontSize={{ base: "16px", md: "20px" }}
                        fontWeight={"normal"}
                        pr={"30"}
                      >
                        As one of the first companies to provide live-in care
                        throughout the UK, we have over 25 years experience in
                        both training our carers and in providing a professional
                        level of live-in care services. We cover it all, from
                        employment matters – salaries, benefits and leave, for
                        example – to providing a wide range of services
                        including everything from complex nursing care, to
                        mobility aids, and more.
                      </Text>
                    </Stack>
                    <HStack spacing="3">
                      <CustomButton
                        as={"a"}
                        link="/healthcare/live-in-care"
                        transition={buttonTransition}
                        _hover={hover}
                        variant="link"
                        label="Learn More"
                        rightIcon={
                          <Icon
                            color={useColorModeValue("blue.500", "blue.300")}
                            as={FaArrowRight}
                          />
                        }
                        color={"blue.400"}
                      />
                    </HStack>
                  </Stack>
                </Box>
                <Flex flex="1" overflow="hidden">
                  <Image
                    src="/images/live-in-care.jpg"
                    alt="Lovely Image"
                    fallback={<Skeleton />}
                    width={"532px"}
                    objectFit="contain"
                    alignSelf={"center"}
                    // flex="1"
                  />
                  <Image
              display={{ base: 'none', sm: 'initial' }}
              alt="Lovely Image"
              fallback={<Skeleton />}
              maxH="450px"
              objectFit="cover"
            />
                </Flex>
              </Stack> */}
              {/* <FeedbackForm /> */}
              <QQualityTag />
            </Box>
          </Box>
        </>
      }
    />
  );
};

export default LitmusHealthcare;
